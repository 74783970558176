import {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function ClientAddForm()
{
    const {managedGym} = useUser();

    const [params] = useSearchParams();
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [loaded, setLoaded] = useState({});

    // Obtener datos
    useEffect(() =>
    {
        request("GET", `/clients/add?gymId=${managedGym.id}`).then(response => 
        {
            setForm(response.data);
            setLoaded(copy => ({...copy, form: true}));
        });
    }, [params, managedGym]);

    // Actualizar "amount" y "details" automaticamente al seleccionar un plan
    useEffect(() =>
    {
        if(!form.planId || !form.plans) return; // El plan es necesario para calcular la renovacion de la membresía
        
        const plan = form.plans.find(plan => (plan.id === parseInt(form.planId))); // Buscar el plan segun el ID
        setForm(copy => ({...copy, amount: plan.price, details: plan.name}));
    }, [form.planId, form.plans]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/clients/add?gymId=${managedGym.id}`, form).then(() => 
        {
            Swal.fire(
            {
                title: "Cliente añadido",
                icon: "success",
                timer: 3000
            })
            .then(() => navigate(-1));
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <Fragment>
            {(loaded.form) ?
                <main>
                    <div className="mb-3">
                        <Link to={"./../"} className="btn btn-light mb-3 bg-white border fw-semibold shadow-sm">
                            <div className="hstack gap-2">
                                <i className="fa-regular fa-chevron-left"></i>
                                <span>Volver</span>
                            </div>
                        </Link>

                        <h4 className="mb-0">Editar cliente</h4>
                    </div>
                    
                    <div className="p-4 bg-white border rounded-3 shadow">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <fieldset className="form-fieldset mb-3">
                                <legend className="fs-6">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-user fa-fw text-primary"></i>
                                        <span className="fw-bold text-uppercase">Datos del cliente</span>
                                    </div>
                                </legend>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="name">Nombre completo <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" id="name" value={form.name || ""} onChange={handleChange} required/>
                                    <small className="text-danger">{errors.name}</small>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="phone">Teléfono</label>
                                    <input className="form-control" type="tel" id="phone" value={form.phone || ""} onChange={handleChange}/>
                                    <small className="text-danger">{errors.phone}</small>
                                </div>

                                <div className="mb-1">
                                    <label className="form-label" htmlFor="documentNumber">Número de documento</label>
                                    <input className="form-control" type="number" id="documentNumber" value={form.documentNumber || ""} onChange={handleChange}/>
                                    <small className="text-danger">{errors.documentNumber}</small>
                                </div>
                            </fieldset>

                            <fieldset className="form-fieldset mb-3">
                                <legend className="fs-6">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-address-card fa-fw text-primary"></i>
                                        <span className="fw-bold text-uppercase">Membresía</span>
                                    </div>
                                </legend>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="startsAt">Fecha de inicio</label>
                                    <input className="form-control" type="date" id="startsAt" value={form.startsAt || ""} onChange={handleChange}/>
                                    <small className="text-danger">{errors.startsAt}</small>
                                    <div className="form-text">Fecha en la que comienza la membresía de este cliente</div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="planId">Seleccionar plan</label>
                                    <select className="form-select" id="planId" value={form.planId || ""} onChange={handleChange} required>
                                        <option value="" disabled>-- Seleccionar opción --</option>
                                        {form.plans.map(plan =>
                                        {
                                            return (
                                                <option value={plan.id} key={plan.id}>{plan.name} ($ {plan.price.toLocaleString()})</option>
                                            );
                                        })}
                                    </select>
                                    <small className="text-danger">{errors.planId}</small>
                                </div>

                                <div className="mb-0">
                                    <label className="form-label" htmlFor="amount">Monto del pago <span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input className="form-control" type="text" id="amount" value={form.amount || ""} onChange={handleChange} disabled={!(form.planId)} required/>
                                    </div>
                                    <small className="text-danger">{errors.amount}</small>
                                </div>
                            </fieldset>

                            <button type="submit" className="btn btn-primary fw-bold">Añadir cliente</button>
                        </form>
                    </div>
                </main>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </Fragment>
    );
} 