import {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function ExpenseForm()
{
    const {managedGym} = useUser();

    const [params] = useSearchParams();
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [loaded, setLoaded] = useState({});

    // Obtener datos
    useEffect(() =>
    {
        const id = params.get("id") || "";
        request("GET", `/expenses/form?id=${id}&gymId=${managedGym.id}`).then(response => 
        {
            setForm(response.data);
            setLoaded(copy => ({...copy, form: true}));
        });
    }, [params, managedGym]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/expenses/form?gymId=${managedGym.id}`, form).then(() => 
        {
            Swal.fire(
            {
                title: (form.id) ? ("Gasto editado") : ("Gasto registrado"),
                icon: "success",
                timer: 3000
            })
            .then(() => navigate(-1));
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <Fragment>
            {(loaded.form) ?
                <main>
                    <div className="mb-3">
                        <Link to={"./../"} className="btn btn-light mb-3 bg-white border shadow-sm">
                            <div className="hstack gap-2">
                                <i className="fa-regular fa-chevron-left"></i>
                                <span>Volver</span>
                            </div>
                        </Link>

                        <h4 className="mb-0">{(form.id) ? ("Editar gasto") : ("Nuevo gasto")}</h4>
                    </div>
                    
                    <div className="p-4 bg-white border rounded-3 shadow">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                        <div className="mb-3">
                                <label className="form-label" htmlFor="type">Tipo de gasto <span className="text-danger">*</span></label>
                                <div className="dropdown">
                                    <button className="btn btn-light w-100 bg-white border text-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                            <span>
                                                {(form.type) ?
                                                    <Fragment>{form.types.find(type => (type.value === form.type)).name}</Fragment>
                                                    :
                                                    <Fragment>-- Seleccionar opción --</Fragment>
                                                }
                                            </span>
                                            <i className="fa-regular fa-chevron-down"></i>
                                        </div>
                                    </button>

                                    <ul className="dropdown-menu w-100 shadow">
                                        <li>
                                            <button className="dropdown-item" type="button" disabled>-- Seleccionar opción --</button>
                                        </li>
                                        {form.types.map(type =>
                                        {
                                            return (
                                                <li key={type.value}>
                                                    <button className="dropdown-item text-wrap" type="button" onClick={() => setForm({...form, type: type.value})}>
                                                        <div>{type.name} <small className="ps-1 text-muted">{type.description}</small></div>
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <small className="text-danger">{errors.type}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="amount">Monto <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input className="form-control" type="text" id="amount" value={form.amount || ""} onChange={handleChange} required/>
                                </div>
                                <small className="text-danger">{errors.amount}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="paymentMethod">Método de pago <span className="text-danger">*</span></label>
                                <select className="form-select" id="paymentMethod" value={form.paymentMethod || ""} onChange={handleChange} required>
                                    <option value="" disabled>-- Seleccionar opción --</option>
                                    {form.paymentMethods.map(method =>
                                    {
                                        return (
                                            <option value={method.value} key={method.value}>{method.name}</option>
                                        );
                                    })}
                                </select>
                                <small className="text-danger">{errors.paymentMethod}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="notes">Notas adicionales</label>
                                <textarea className="form-control" id="notes" value={form.notes || ""} onChange={handleChange} rows={2}></textarea>
                                <small className="text-danger">{errors.notes}</small>
                            </div>

                            <button type="submit" className="btn btn-primary fw-bold">{(form.id) ? "Guardar datos" : "Registrar gasto"}</button>
                        </form>
                    </div>
                </main>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </Fragment>
    );
} 