import {Link} from 'react-router-dom';
import {Fragment} from 'react';

import {panelLinks} from '../../routes/panelRoutes';
import {useUser} from '../../contexts/UserContext';


export default function OffcanvasSidebar()
{
    const {user} = useUser();


    if(!user) return null;
    return (
        <div className="offcanvas offcanvas-start text-bg-dark" tabIndex="-1" id="offcanvasPanelSidebar">
            <div className="offcanvas-header justify-content-between">
                <div className="hstack gap-3">
                    <i className="fa-thin fa-user-circle fa-2xl text-primary"></i>

                    <div>
                        <p className="mb-0">{user.name} {user.surname}</p>
                        <p className="mb-0 small fw-semibold">{user.email}</p>
                    </div>
                </div>

                <button className="p-2 btn-unstyled text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                    <i className="fa-solid fa-x"></i>
                </button>
            </div>

            <div className="offcanvas-body">
                <div className="vstack gap-2">

                    {/* 
                        <Link to="/mis-gimnasios" className="fw-semibold fs-5 text-decoration-none text-reset">
                            <div className="px-3 py-2 text-bg-primary-hover rounded hstack gap-2">
                                <i className="fa-solid fa-buildings fa-fw fa-sm"></i>
                                <span>Mis gimnasios</span>
                            </div>
                        </Link>
                    */}

                    {panelLinks.map(link =>
                    {
                        const collapse = `collapse_${link.href}`;

                        return (
                            <Fragment key={link.href}>
                                {(link.subLinks) ?
                                    <div>
                                        <button className="btn-unstyled fw-semibold fs-5 text-reset w-100 px-3 py-2 text-bg-primary-hover rounded" type="button" data-bs-toggle="collapse" data-bs-target={"#" + collapse} aria-expanded="false" aria-controls={collapse}>
                                            <div className="d-flex align-items-center justify-content-between gap-3">
                                                <div className="hstack gap-2">
                                                    <i className={`fa-thin ${link.icon} fa-fw`}></i>
                                                    <span>{link.title}</span>
                                                </div>

                                                <i className="fa-regular fa-chevron-down"></i>
                                            </div>
                                        </button>

                                        <div className="collapse ps-4" id={collapse}>
                                            <div className="vstack gap-1">
                                                {/* <Link to={link.href} className={`mt-1 btn-unstyled fw-semibold fs-5 text-reset`}>
                                                    <div className="px-3 py-2 text-bg-primary-hover rounded">
                                                        Ver todos
                                                    </div>
                                                </Link> */}

                                                {link.subLinks.map(subLink =>
                                                {
                                                    return (
                                                        <Link to={link.href + "/" + subLink.href} className={`btn-unstyled fw-semibold fs-5 text-reset`} key={subLink.href}>
                                                            <div className="px-3 py-2 text-bg-primary-hover rounded">
                                                                {subLink.title}
                                                            </div>
                                                        </Link>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Link to={link.href} className={`btn-unstyled fw-semibold fs-5 text-reset`}>
                                        <div className="hstack gap-2 px-3 py-2 text-bg-primary-hover rounded">
                                            <i className={`fa-regular ${link.icon} fa-fw`}></i>
                                            <span>{link.title}</span>
                                        </div>
                                    </Link>
                                }
                            </Fragment>
                        );
                    })}
                </div> 
            </div>
        </div>
    );
}
