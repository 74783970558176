import React, {Fragment, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";
import SubscriptionStatus from "../../enums/SubscriptionStatus";



export default function Gyms()
{
    const navigate = useNavigate();
    const {manageGym} = useUser();

    const [gyms, setGyms] = useState(null);
    const [loaded, setLoaded] = useState({});


    useEffect(() =>
    {
        request("GET", "/admin/gyms").then(response => 
        {
            setGyms(response.data);
            setLoaded(copy => ({...copy, list: true}));
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, []);


    const onSelectGym = gym =>
    {
        manageGym(gym);
        navigate("/panel/clientes");
    };


    return (
        <main className="container my-5">
            <div className="mb-3">
                <h3 className="mb-0 fw-semibold">Gimnasios</h3>
            </div>

            <div className="mb-3">
                <Link to={"form"} className="btn btn-success">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-plus"></i>
                        <span>Adherir gimnasio</span>
                    </div>
                </Link>
            </div>

            {/*  */}
            {(loaded.list) ?
                <div className="table-responsive">
                    <table className="table table-transparent table-bordered align-middle">
                        <thead>
                            <tr className="text-bg-primary">
                                <th className="text-reset">Logo</th>
                                <th className="text-reset">Nombre</th>
                                <th className="text-reset">Dueño</th>
                                <th className="text-reset">Dirección</th>
                                <th className="text-reset">Teléfono</th>
                                <th className="text-reset">Suscripción</th>
                                <th className="text-reset"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {gyms.map(gym =>
                            {
                                return (
                                    <tr key={gym.id}>
                                        <td className="text-center">
                                            {(gym.logoUrl) &&
                                                <img src={gym.logoUrl} className="w-100 object-fit-contain" style={{maxHeight: "2.5rem"}} alt="Logo"/>
                                            }
                                        </td>
                                        <td className="text-nowrap">
                                            <div className="hstack gap-2">
                                                <i className="fa-solid fa-building text-primary"></i>
                                                <span>{gym.name}</span>
                                            </div>
                                        </td>
                                        <td className="text-nowrap">
                                            <p className="mb-0">{gym.owner.name} {gym.owner.surname}</p>
                                            <small className="text-muted">@{gym.owner.username}</small>
                                        </td>
                                        <td className="text-nowrap">{gym.address}</td>
                                        <td className="text-nowrap">{gym.phone}</td>
                                        <td>
                                            {(gym.subscription) ?
                                                <Fragment>
                                                    <div className="fw-semibold">
                                                        {(gym.subscription.status.value === SubscriptionStatus.ACTIVE) && <span className="text-success">Activo</span>}
                                                        {(gym.subscription.status.value === SubscriptionStatus.PENDING) && <span className="text-warning">Pendiente de pago</span>}
                                                        {(gym.subscription.status.value === SubscriptionStatus.EXPIRED) && <span className="text-danger">Vencido</span>}
                                                    </div>

                                                    {(gym.subscription.daysUntilExpiration >= 0) ?
                                                        <small>Vence en {gym.subscription.daysUntilExpiration} días</small>
                                                        :
                                                        <small>Venció hace {-gym.subscription.daysUntilExpiration} días</small>
                                                    }
                                                </Fragment>
                                                :
                                                <span>Sin suscripción</span>
                                            }
                                        </td>
                                        <td>
                                            <div className="hstack gap-2">
                                                <button className="btn btn-sm btn-primary" type="button" onClick={() => onSelectGym(gym)} title="Ir al panel">
                                                    <i className="fa-regular fa-gauge"></i>
                                                </button>

                                                <Link to={`/admin/suscripciones/form?gymId=${gym.id}`} className="btn btn-sm btn-light" title="Gestionar suscripción">
                                                    <i className="fa-regular fa-money-check"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <Fragment>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </Fragment>
            }
        </main>
    );
} 