import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function Plans()
{
    const {managedGym} = useUser();

    const [plans, setPlans] = useState(null);
    const [loaded, setLoaded] = useState({});


    useEffect(() =>
    {
        if(!managedGym) return;

        request("GET", `/plans/?gymId=${managedGym.id}`).then(response => 
        {
            setPlans(response.data);
            setLoaded(copy => ({...copy, list: true}));
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });

        document.title = `Planes - ${managedGym.name}`;
    }, [managedGym]);


    return (
        <main>
            <div className="mb-4 d-flex flex-wrap align-items-center justify-content-between gap-2">
                <h3 className="mb-0 fw-semibold">Planes</h3>

                <Link to={"form"} type="button" className="btn btn-primary fw-semibold">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-plus"></i>
                        <span>Nuevo plan</span>
                    </div>
                </Link>
            </div>

            {/*  */}
            {(loaded.list) ?
                <div className="table-responsive">
                    <table className="table table-transparent table-bordered table-hover caption-top align-middle">
                        <caption>Lista de planes</caption>

                        <thead>
                            <tr className="text-bg-primary">
                                <th className="text-reset">Nombre del plan</th>
                                <th className="text-reset">Duración</th>
                                <th className="text-reset">Precio</th>
                                <th className="text-reset"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {plans.map(plan =>
                            {
                                return (
                                    <tr key={plan.id}>
                                        <td className="text-nowrap">
                                            <div className="hstack gap-2">
                                                <i className="fa-solid fa-comment-dollar text-primary"></i>
                                                <span>{plan.name}</span>
                                            </div>
                                        </td>
                                        <td className="text-nowrap">
                                            {(plan.timeUnit) ?
                                                <span className="text-lowercase">{plan.duration} {plan.timeUnit.name}</span>
                                                :
                                                <span className="text-muted">(Sin especificar)</span>
                                            }
                                        </td>
                                        <td className="text-nowrap fw-semibold">$ {plan.price.toLocaleString()}</td>
                                        <td className="text-center">
                                            <Link to={`form?id=${plan.id}`} className="btn btn-sm btn-light" type="button" title="Editar plan">
                                                <i className="fa-regular fa-pen-square"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 