import {useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {hideModals} from "../../services/UtilsService";


export default function ClientMembershipModal({client, modal, onSubmit})
{
    const {expiresAt} = client;
    
    const [form, setForm] = useState({expiresAt});
    const [errors, setErrors] = useState({});


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/clients/membership?id=${client.id}`, form).then(() => 
        {
            hideModals();

            Swal.fire(
            {
                title: "Membresía editada",
                icon: "success",
                timer: 3000
            })
            .then(() => {
                onSubmit();
            });

        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-labelledby={modal + "Label"} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-semibold" id={modal + "Label"}>Editar membresía</h5>

                        <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>


                    <form onSubmit={onFormSubmit}>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="expiresAt">Fecha de vencimiento <span className="text-danger">*</span></label>
                                <input className="form-control" type="date" id="expiresAt" value={form.expiresAt || ""} onChange={handleChange} required/>
                                <small className="text-danger">{errors.expiresAt}</small>
                                <div className="form-text">Indica cuándo debe vencer la membresía de este cliente</div>
                            </div>

                            <div className="alert alert-warning mb-0" role="alert">
                                <div className="hstack gap-2">
                                    <i className="fa-regular fa-exclamation-circle"></i>
                                    <span>Modifique la fecha de vencimiento solo en casos excepcionales, como errores o inconsistencias.<br/>Las membresías se actualizan automáticamente al registrar pagos.</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-light border" type="button" data-bs-dismiss="modal">Cancelar</button>
                            <button className="btn btn-primary fw-bold" type="submit">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}