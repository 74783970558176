import {Fragment, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function ProductsForm()
{
    const {managedGym} = useUser();

    const [params] = useSearchParams();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [loaded, setLoaded] = useState({});

    // Obtener datos
    useEffect(() =>
    {
        const id = params.get("id") || "";
        request("GET", `/products/form?id=${id}`).then(response => 
        {
            setForm(response.data);
            setLoaded(copy => ({...copy, form: true}));
        });
    }, [params]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/products/form?gymId=${managedGym.id}`, form).then(() => 
        {
            Swal.fire(
            {
                title: (form.id) ? ("Producto editado") : ("Producto añadido"),
                icon: "success",
                timer: 3000,
            });
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <main>
            <div className="mb-3">
                <Link to={"./../"} className="btn btn-light bg-white border shadow-sm">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-chevron-left"></i>
                        <span>Volver</span>
                    </div>
                </Link>
            </div>

            {(loaded.form) ?
                <Fragment>
                    <div className="mb-3">
                        <h4 className="mb-0">{(form.id) ? ("Editar producto") : ("Nuevo producto")}</h4>
                    </div>
                    
                    <div className="p-4 bg-white border rounded-3 shadow">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="name">Nombre del producto <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="name" value={form.name || ""} onChange={handleChange} required/>
                                <small className="text-danger">{errors.name}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="unitPrice">Precio unitario <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input className="form-control" type="number" id="unitPrice" value={form.unitPrice || ""} onChange={handleChange} required/>
                                </div>
                                <small className="text-danger">{errors.unitPrice}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="stock">Stock</label>
                                <input className="form-control" type="number" id="stock" value={form.stock || ""} onChange={handleChange}/>
                                <small className="text-danger">{errors.stock}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="measurement">Unidad de medida <span className="text-danger">*</span></label>
                                <select className="form-select" id="measurement" value={form.measurement || ""} onChange={handleChange} required>
                                    <option value="" disabled>-- Seleccionar opción --</option>
                                    {form.measurements.map(measurement =>
                                    {
                                        return (
                                            <option value={measurement.value} key={measurement.value}>{measurement.name} ({measurement.shortName})</option>
                                        );
                                    })}
                                </select>
                                <small className="text-danger">{errors.measurement}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="description">Descripción</label>
                                <textarea className="form-control" id="description" value={form.description || ""} onChange={handleChange} rows={2}></textarea>
                                <small className="text-danger">{errors.description}</small>
                            </div>

                            <button type="submit" className="btn btn-primary fw-bold">{(form.id) ? "Guardar datos" : "Añadir producto"}</button>
                        </form>
                    </div>
                </Fragment>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 