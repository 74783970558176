import Swal from "sweetalert2";


// Ocultar todos los modals de bootstrap
export function hideModals()
{
    const modals = document.querySelectorAll(".modal");
    modals.forEach(modal =>
    {
        var instance = window.bootstrap.Modal.getInstance(modal);
        if(instance !== null) instance.hide();
    });
}

// Oculta todos los canvas de bootstrap
export function hideOffcanvas()
{
    const offcanvas = document.querySelectorAll(".offcanvas");
    offcanvas.forEach(offcanva =>
    {
        var instance = window.bootstrap.Offcanvas.getInstance(offcanva);
        if(instance !== null) instance.hide();
    });
}

// Verifica si el usuario tiene permisos para acceder a cierto modulo y accion
export const hasPermission = (user, gym, action) =>
{
    return true;
    // // Esperar a que cargue la vista
    // if(!user || !gym) return false;

    // // Los administradores de la plataforma pueden ver todo
    // if(user.roles.includes(UserRole.ADMIN)) return true;

    // // Si no se especifica un action, entonces retornar true
    // if(!action) return true;

    // // Buscar permisos
    // const permission = gym.role.actions.find(permission => (permission === action));
    // if(permission) return true; // Permiso encontrado

    // return false; // No tiene permisos
};


// Imprimir
export function print(src)
{
    // Mostrar toast
    Swal.fire(
    {
        title: "Por favor espere...",
        timer: 3000
    });

    const iframe = document.createElement("IFRAME"); // Crear un iframe
    iframe.src = src; // Establecer el src del iframe
    iframe.style.display = "none"; // No mostrar el iframe
    iframe.onload = () =>
    {
        const win = iframe.contentWindow || iframe;
        win.document.querySelector("html").setAttribute("data-bs-theme", "light");
        win.focus();
    };
    document.body.appendChild(iframe);
}