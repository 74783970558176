import React, {Fragment, useEffect, useState} from "react";
import Swal from "sweetalert2";
import moment from "moment";
import Chart from "chart.js/auto";
import {Pie} from "react-chartjs-2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";
import {print} from "../../services/UtilsService";


export default function Summary()
{
    const {managedGym} = useUser();

    const fromDate = new Date().setDate(1);
    const toDate = new Date();

    const [summary, setSummary] = useState([]);
    const [filters, setFilters] = useState({from: moment(fromDate).format("yyyy-MM-DD"), to: moment(toDate).format("yyyy-MM-DD")});
    const [loaded, setLoaded] = useState({});


    // Cuando carga la vista
    useEffect(() =>
    {
        if(!managedGym) return;

        document.title = `Resumen - ${managedGym.name}`;
    }, [managedGym]);

    // Cuando cambian los filtros de busqueda
    useEffect(() =>
    {
        if(!managedGym || !filters) return;

        setLoaded(copy => ({...copy, list: false}));
        request("GET", `/gyms/summary?id=${managedGym.id}&from=${filters.from}&to=${filters.to}`).then(response => 
        {
            setSummary(response.data);
            setLoaded(copy => ({...copy, list: true}));
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedGym, filters]);


    // Cambiar los filtros de fecha
    const onFilterByDate = e =>
    {
        e.preventDefault();

        const from = document.querySelector("input#from").value;
        const to = document.querySelector("input#to").value;
        setFilters({...filters, from, to});
    };


    return (
        <main>
            <div className="mb-3 d-flex flex-wrap align-items-center justify-content-between gap-2">
                <h3 className="mb-0 fw-semibold">Resumen</h3>

                <div>
                    <form onSubmit={onFilterByDate}>
                        <div className="hstack flex-wrap align-items-end gap-3">
                            <div>
                                <label className="form-label mb-1" htmlFor="from">Desde</label>
                                <input className="form-control" type="date" id="from" defaultValue={filters.from || ""} required/>
                            </div>

                            <div>
                                <label className="form-label mb-1" htmlFor="to">Hasta</label>
                                <input className="form-control" type="date" id="to" defaultValue={filters.to || ""} required/>
                            </div>

                            <button className="btn btn-success">
                                <i className="fa-regular fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/*  */}
            {(loaded.list) ?
                <Fragment>
                    <div className="mb-3">
                        <button className="btn btn-primary rounded-0 fw-bold" type="button" onClick={() => print(`/imprimir/pagos?gymId=${managedGym.id}&from=${filters.from}&to=${filters.to}`)}>
                            <div className="hstack gap-2">
                                <i className="fa-regular fa-file-lines"></i>
                                <span>Generar reporte</span>
                            </div>
                        </button>
                    </div>

                    <div className="row align-items-start g-3">
                        <div className="col-12 col-md-6">
                            <div className="p-3 bg-white border rounded-3 shadow">
                                <div className="mb-3">
                                    <Pie
                                        style={{maxHeight: "20rem"}}
                                        options={{maintainAspectRatio: true}}
                                        data=
                                        {{
                                            labels: ["Membresías", "Ventas", "Gastos"],
                                            datasets: 
                                            [{
                                                label: "Total",
                                                data: [summary.totalMemberships, summary.totalSales, summary.totalExpenses],
                                                hoverOffset: 8
                                            }]
                                        }}
                                        >
                                    </Pie>
                                </div>

                                <table className="table table-sm table-borderless mb-0 align-middle">
                                    <tbody>
                                        <tr>
                                            <td className="w-100">
                                                <div className="hstack gap-2">
                                                    <i className="fa-regular fa-address-card fa-fw text-primary"></i>
                                                    <span>Membresías</span>
                                                </div>
                                            </td>
                                            <td className="fw-bold text-nowrap">$ {summary.totalMemberships.toLocaleString()}</td>
                                        </tr>

                                        <tr>
                                            <td className="w-100">
                                                <div className="hstack gap-2">
                                                    <i className="fa-regular fa-sack-dollar fa-fw text-primary"></i>
                                                    <span>Ventas</span>
                                                </div>
                                            </td>
                                            <td className="fw-bold text-nowrap">$ {summary.totalSales.toLocaleString()}</td>
                                        </tr>

                                        <tr>
                                            <td className="w-100">
                                                <div className="hstack gap-2">
                                                    <i className="fa-regular fa-money-bill fa-fw text-primary"></i>
                                                    <span>Gastos</span>
                                                </div>
                                            </td>
                                            <td className="fw-bold text-nowrap">$ {summary.totalExpenses.toLocaleString()}</td>
                                        </tr>

                                        <tr>
                                            <td className="w-100">
                                                <div className="hstack gap-2">
                                                    <i className="fa-regular fa-scale-balanced fa-fw text-primary"></i>
                                                    <span>Balance</span>
                                                </div>
                                            </td>
                                            <td className={`fw-bold text-nowrap ${(summary.balance >= 0) ? "text-success" : "text-danger"}`}>$ {summary.balance.toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="p-3 bg-white border rounded-3 shadow">
                                <div className="mb-3">
                                    <h3 className="fs-5">Gastos por categoría</h3>
                                </div>

                                <table className="table table-sm table-borderless align-middle mb-0">
                                    <tbody>
                                        {summary.expenseTypes.map(type =>
                                        {
                                            return (
                                                <tr key={type.value}>
                                                    <td className="w-100">{type.name}</td>
                                                    <td className="fw-bold text-nowrap">$ {type.total.toLocaleString()}</td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td className="w-100 fs-5">Total</td>
                                            <td className="fw-bold fs-5 text-nowrap">$ {summary.totalExpenses.toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="p-4 bg-white border rounded-3 shadow">
                                <div className="gap-3">
                                    <h3 className="fs-5">Productos más vendidos</h3>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-transparent table-sm table-bordered align-middle mb-0">
                                        <thead className="text-bg-primary align-middle">
                                            <tr>
                                                <th className="text-reset"></th>
                                                <th className="text-reset">Producto</th>
                                                <th className="text-reset text-center">Cantidad vendida</th>
                                                <th className="text-reset text-center">Total generado</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {summary.products.map((item, itemIndex) =>
                                            {
                                                return (
                                                    <tr className={(itemIndex < 3) ? ("fs-6 fw-semibold") : ("")} key={itemIndex}>
                                                        <td className="text-center">{itemIndex + 1}°</td>
                                                        <td>{item.product.name}</td>
                                                        <td className="text-center">{item.quantity} {item.product.measurement?.shortName}</td>
                                                        <td className="text-center fw-bold text-nowrap">$ {item.subTotal.toLocaleString()}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 