import {Fragment, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function SaleForm()
{
    const {managedGym} = useUser();

    const [params] = useSearchParams();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [sale, setSale] = useState({total: 0, paidAmount: 0});
    const [loaded, setLoaded] = useState({});
    

    // Obtener datos
    useEffect(() =>
    {
        const id = params.get("id") || "";
        request("GET", `/sales/form?id=${id}`).then(response => 
        {
            setForm(response.data);
            setLoaded(copy => ({...copy, form: true}));
        });
    }, [params]);

    // Calcular el total cuando cambien los items
    useEffect(() =>
    {
        if(!form.items) return;

        const total = parseFloat(form.items.reduce((acc, value) => (acc + (value.unitPrice * value.quantity)), 0));
        setSale(copy => ({...copy, total}));
    }, [form.items]);

    // Calcular el total pagado
    useEffect(() =>
    {
        if(!form.payments) return;

        const paidAmount = parseFloat(form.payments.reduce((acc, value) => (acc + (value.amount)), 0));
        setSale(copy => ({...copy, paidAmount}));
    }, [form.payments]);
    

    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const addItem = (property, value) =>
    {
        const items = [...form[property] || []];
        items.push(value);
        setForm({...form, [property]: items});
    };
    
    const changeItem = (property, index, key, value) =>
    {
        const items = [...form[property] || []];
        items[index][key] = value;
        setForm({...form, [property]: items});
    };

    const removeItem = (property, index) =>
    {
        let items = [...form[property]];
        items.splice(index, 1);
        setForm({...form, [property]: items});
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/sales/form?gymId=${managedGym.id}`, form).then(() => 
        {
            Swal.fire(
            {
                title: (form.id) ? ("Venta editada") : ("Venta registrada"),
                icon: "success",
                timer: 3000,
            });
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };

    // AsyncSelect
    let debounceTimeout = null;

    const promiseOptions = (inputValue) => 
    {
        if(inputValue.trim().length === 0) return null;

        return new Promise((resolve) => 
        {
            if(debounceTimeout) clearTimeout(debounceTimeout); // Limpia el timeout anterior si el usuario sigue escribiendo
      
            // Establece un nuevo timeout de 500ms (o el tiempo que prefieras)
            debounceTimeout = setTimeout(() => 
            {
                // Realiza la petición después del delay
                request("GET", `/products/search?gymId=${managedGym.id}&query=${inputValue}`).then((response) => 
                {
                    const options = response.data.map((option) => ({value: option.id, label: option.name, data: option}));
                    resolve(options); // Resuelve la promesa con las opciones
                })
                .catch(() => {
                    resolve([]); // Si hay un error, devuelve un array vacío
                });
            }, 750); // 500ms de delay para el debounce
        });
    };

    const onSelectProduct = option =>
    {
        const product = option.data;

        const item = 
        {
            id: null,
            product,
            productId: product.id,
            unitPrice: product.unitPrice,
            quantity: 1,
        };
        
        const formEx = {...form};
        formEx.items = [...formEx.items, item];
        setForm(formEx);
    };


    return (
        <main>
            <div className="mb-3">
                <Link to={"./../"} className="btn btn-light bg-white border shadow-sm">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-chevron-left"></i>
                        <span>Volver</span>
                    </div>
                </Link>
            </div>

            {(loaded.form) ?
                <Fragment>
                    <div className="mb-3">
                        <h4 className="mb-0">{(form.id) ? ("Editar venta") : ("Registrar venta")}</h4>
                    </div>
                    
                    <div className="p-4 bg-white border rounded-3 shadow">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="">Productos</label>

                                <div className="input-group flex-nowrap">
                                    <div className="input-group-text">
                                        <i className="fa-regular fa-barcode"></i>
                                    </div>

                                    <div className="flex-fill">
                                        <AsyncSelect
                                            loadOptions={promiseOptions}
                                            placeholder="Buscar producto"
                                            onChange={onSelectProduct}
                                            loadingMessage={e => `${(e.inputValue.length === 0) ? ("Escribe el nombre del producto") : ("Buscando...")}`}
                                            noOptionsMessage={e => `${(e.inputValue.length === 0) ? ("Escribe el nombre del producto") : ("Sin resultados")}`}
                                            value={null}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="table-responsive">
                                    <table className="table table-transparent table-bordered table-hover align-middle mb-2">
                                        <thead>
                                            <tr className="text-bg-primary">
                                                <th className="text-reset">Nombre</th>
                                                <th className="text-reset">Precio unitario</th>
                                                <th className="text-reset">Cantidad</th>
                                                <th className="text-reset">Subtotal</th>
                                                <th className="text-reset"></th>
                                            </tr>
                                        </thead>
        
                                        <tbody>
                                            {form.items.map((item, i) =>
                                            {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="hstack gap-2">
                                                                <i className="fa-regular fa-box fa-fw text-primary"></i>
                                                                <span>{item.product.name}</span>
                                                            </div>
                                                        </td>
                                                        <td style={{minWidth: "10rem", maxWidth: "15rem"}}>
                                                            <div className="input-group">
                                                                <span className="input-group-text">$</span>
                                                                <input className="form-control" type="number" value={form.items[i].unitPrice || ""} onChange={e => changeItem("items", i, "unitPrice", e.target.value)} required/>
                                                            </div>
                                                            <small className="text-danger">{errors[`items[${i}].unitPrice`]}</small>
                                                        </td>
                                                        <td style={{minWidth: "6rem", maxWidth: "10rem"}}>
                                                            <input className="form-control" type="number" value={form.items[i].quantity || ""} onChange={e => changeItem("items", i, "quantity", e.target.value)} required/>
                                                            <small className="text-danger">{errors[`items[${i}].quantity`]}</small>
                                                        </td>
                                                        <td className="fw-semibold text-nowrap">$ {(item.unitPrice * item.quantity).toLocaleString()}</td>
                                                        <td>
                                                            <div className="text-center">
                                                                <button className="btn btn-light" type="button" title="Quitar producto" onClick={() => removeItem("items", i)}>
                                                                    <i className="fa-regular fa-x"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <table className="align-middle">
                                        <tbody>
                                            <tr>
                                                <td className="pe-4 fs-5">Total</td>
                                                <td className="fw-semibold fs-5">$ {sale.total.toLocaleString()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Pagos</label>

                                {form.payments.map((_, i) =>
                                {
                                    return (
                                        <div className="hstack gap-2 mb-2" key={i}>
                                            <div className="flex-fill">
                                                <select className="form-select" id={`payments${i}.method`} value={form.payments[i].method || ""} onChange={e => changeItem("payments", i, "method", e.target.value)} required>
                                                    <option value="" disabled>-- Seleccionar opción --</option>
                                                    {form.paymentMethods.map(method =>
                                                    {
                                                        return (
                                                            <option value={method.value} key={method.value}>{method.name}</option>
                                                        );
                                                    })}
                                                </select>
                                                <small className="text-danger">{errors[`payments[${i}].method`]}</small>
                                            </div>

                                            <div className="flex-fill">
                                                <div className="input-group">
                                                    <span className="input-group-text">$</span>
                                                    <input className="form-control" type="number" value={form.payments[i].amount || ""} onChange={e => changeItem("payments", i, "amount", e.target.value)} required/>
                                                </div>
                                                <small className="text-danger">{errors[`payments[${i}].amount`]}</small>
                                            </div>

                                            <div>
                                                <button className="btn btn-light" type="button" onClick={() => removeItem("payments", i)} title="Quitar pago">
                                                    <i className="fa-regular fa-minus fa-sm"></i>
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div>
                                    <small className="text-danger">{errors.payments}</small>
                                </div>

                                <div>
                                    <button className="btn btn-sm btn-success" type="button" onClick={() => addItem("payments", {})}>
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="mb-3">
                                <table className="table table-light table-bordered align-middle">
                                    <tbody>
                                        <tr>
                                            <td className="">Total a pagar</td>
                                            <td className="fw-semibold fs-5">$ {sale.total.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td className="">Total pagado</td>
                                            <td className="fw-semibold fs-5">$ {sale.paidAmount.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td className="">Balance</td>
                                            <td className="fw-semibold fs-5">$ {(sale.paidAmount - sale.total).toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="details">Detalles adicionales</label>
                                <textarea className="form-control" id="details" value={form.details || ""} onChange={handleChange} rows={2}></textarea>
                                <small className="text-danger">{errors.details}</small>
                            </div>

                            <button type="submit" className="btn btn-primary fw-bold">{(form.id) ? "Guardar datos" : "Registrar venta"}</button>
                        </form>
                    </div>
                </Fragment>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 