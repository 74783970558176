import {useEffect, useState} from "react";
import moment from "moment";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {hideModals} from "../../services/UtilsService";
import SubscriptionStatus from "../../enums/SubscriptionStatus";


export default function ClientPaymentModal({client, plans, modal, onSubmit})
{
    const [form, setForm] = useState({date: moment().format("yyyy-MM-DD")});
    const [errors, setErrors] = useState({});


    // Actualizar "amount" y "details" automaticamente al seleccionar un plan
    useEffect(() =>
    {
        if(!form.planId || !plans) return; // El plan es necesario para calcular la renovacion de la membresía
        
        const plan = plans.find(plan => (plan.id === parseInt(form.planId))); // Buscar el plan segun el ID
        setForm(copy => ({...copy, amount: plan.price, details: plan.name}));
    }, [form.planId, plans]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/clients/payment?clientId=${client.id}`, form).then(() => 
        {
            hideModals();

            Swal.fire(
            {
                title: "Pago registrado",
                icon: "success",
                timer: 3000
            })
            .then(() => {
                onSubmit();
            });

        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <div className="modal" id={modal} tabIndex="-1" aria-labelledby={modal + "Label"} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-semibold" id={modal + "Label"}>Registrar pago</h5>

                        <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>


                    <form onSubmit={onFormSubmit}>
                        <div className="modal-body">
                            {(client.subscriptionStatus.value === SubscriptionStatus.EXPIRED) &&
                                <div className="alert alert-warning" role="alert">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-exclamation-circle"></i>
                                        <span>Se recomienda reiniciar la membresía del cliente antes de registrar este pago.</span>
                                    </div>
                                </div>
                            }

                            <div className="mb-3">
                                <label className="form-label" htmlFor="planId">Plan a pagar <span className="text-danger">*</span></label>
                                <select className="form-select" id="planId" value={form.planId || ""} onChange={handleChange}>
                                    <option value="" disabled>-- Seleccionar opción --</option>
                                    {plans.map(plan => 
                                    {
                                        return (
                                            <option value={plan.id} key={plan.id}>{plan.name} ($ {plan.price.toLocaleString()})</option>
                                        );
                                    })}
                                </select>
                                
                                <small className="text-danger">{errors.planId}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="amount">Monto <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <span className="input-group-text">$</span>
                                    <input className="form-control" type="text" id="amount" value={form.amount || ""} onChange={handleChange} disabled={!(form.planId)} required/>
                                </div>
                                <small className="text-danger">{errors.amount}</small>
                                <div className="form-text">Puedes editar el monto en caso de ser necesario</div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="date">Fecha <span className="text-danger">*</span></label>
                                <input className="form-control" type="date" id="date" value={form.date || ""} onChange={handleChange} required/>
                                <small className="text-danger">{errors.date}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="details">Detalles adicionales</label>
                                <textarea className="form-select" id="details" value={form.details || ""} onChange={handleChange} rows={2}></textarea>
                                <small className="text-danger">{errors.details}</small>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-light border" type="button" data-bs-dismiss="modal">Cancelar</button>
                            <button className="btn btn-primary fw-bold" type="submit">Registrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}