import {Fragment, useEffect, useState} from "react";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import { useUser } from "../../contexts/UserContext";


export default function Settings()
{
    const {managedGym, manageGym} = useUser();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [loaded, setLoaded] = useState({});

    
    // Obtener datos
    useEffect(() =>
    {
        if(!managedGym) return;
        
        request("GET", `/gyms/form?id=${managedGym.id}`).then(response => 
        {
            setForm(response.data);
            setLoaded(copy => ({...copy, form: true}));
        });
    }, [managedGym]);

    // Logo
    const handleLogoClick = e =>
    {
        const input = document.querySelector("input#logo");
        input.click();
    };

    const handleLogoChange = e =>
    {
        //
        const input = e.target;
        const logo = input.files[0];
        if(!logo) return;

        request("POST", `/gyms/logo?id=${managedGym.id}`, {logo}, true).then(response => 
        {
            // Actualizar los datos del componente
            const gym = response.data;
            manageGym(gym);

            // Mostrar swal
            Swal.fire("Logo actualizado", "El logo del gimnasio fue subido correctamente", "success");

            // Eliminar archivo
            input.value = "";
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/gyms/form?id=${managedGym.id}`, form).then(response => 
        {
            // Actualizar los datos del compoenten
            const gym = response.data;
            manageGym(gym);

            // Mostrar swal
            Swal.fire("Datos actualizados", "Los datos del gimnasio fueron actualizados correctamente", "success");
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <main>
            <div className="mb-3">
                <h3 className="mb-0 fw-semibold">Ajustes</h3>
            </div>

            {(loaded.form) ?
                <Fragment>
                    <div className="p-4 bg-white border rounded shadow">
                        {/* Logo */}
                        <div className="mb-5">
                            <div className="hstack gap-4">
                                <div>
                                    {(form.logoUrl) ?
                                        <img 
                                            src={form.logoUrl}
                                            className="w-100 object-fit-contain"
                                            alt="Logo"
                                            style={{maxHeight: "5rem"}}
                                        />
                                        :
                                        <img 
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png"
                                            className="w-100 object-fit-contain"
                                            alt="Logo"
                                            style={{maxHeight: "5rem"}}
                                        />
                                    }
                                </div>

                                <div>
                                    <div className="mb-1">
                                        <button className="btn btn-outline-primary fw-semibold" type="button" onClick={handleLogoClick}>Actualizar logo</button>
                                        <input className="d-none" type="file" id="logo" accept="image/png, image/jpeg" onChange={handleLogoChange} required/>
                                        <small className="text-danger">{errors.logo}</small>
                                    </div>

                                    <div className="form-text">Seleccione un archivo .png/.jpg hasta máximo 1MB</div>
                                </div>
                            </div>
                        </div>

                        {/* Formulario */}
                        <form onSubmit={onFormSubmit}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="name">Nombre del gimnasio <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="name" value={form.name || ""} onChange={handleChange} required/>
                                <small className="text-danger">{errors.name}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="phone">Número de teléfono</label>
                                <input className="form-control" type="tel" id="phone" value={form.phone || ""} onChange={handleChange}/>
                                <small className="text-danger">{errors.phone}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="address">Dirección</label>
                                <input className="form-control" type="text" id="address" value={form.address || ""} onChange={handleChange}/>
                                <small className="text-danger">{errors.address}</small>
                            </div>

                            <button className="btn btn-primary fw-semibold" type="submit">Guardar</button>
                        </form>
                    </div>
                </Fragment>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 