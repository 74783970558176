import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import {useUser} from "../../contexts/UserContext";
import {request} from "../../services/AxiosService";


export default function MyGyms()
{
    const navigate = useNavigate();

    const {manageGym} = useUser();
    const [gyms, setGyms] = useState([]);


    useEffect(() =>
    {
        request("GET", "/gyms/").then(response => {
            setGyms(response.data);
        })
        .catch(() =>
        {
            Swal.fire("Ocurrió un error", "Al parecer no tienes permisos para ver esta página.", "error").then(() => {
                navigate("/");
            });
        });
    }, [navigate]);

    useEffect(() =>
    {
        if(gyms.length === 1) {
            onSelectGym(gyms[0]);
        }
    });


    const onSelectGym = gym =>
    {
        manageGym(gym);
        navigate("/panel/clientes");
    };


    return (
        <main className="container-xl py-5">
            <div className="mb-5 text-center text-md-start">
                <h2 className="mb-0">Mis gimnasios</h2>
                <p className="text-muted">Selecciona el gimnasio que quieres administrar</p>
            </div>

            {(gyms) ?
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {gyms.map(gym =>
                    {
                        return (
                            <div className="col" key={gym.id}>
                                <div className="p-2 bg-primary bg-opacity-10 border border-primary rounded-3 shadow">
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <div className="hstack gap-3">
                                            <div>
                                                <img src={gym.logoUrl} alt="Logo" style={{width: "4rem", height: "4rem"}}/>
                                            </div>

                                            <div>
                                                <h3 className="mb-0">{gym.name}</h3>
                                            </div>
                                        </div>

                                        <div className="p-3">
                                            <button className="btn btn-sm btn-primary" onClick={() => onSelectGym(gym)}>
                                                <div className="hstack gap-2">
                                                    <span className="fw-semibold">Ingresar</span>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                :
                <p className="fs-5 text-center text-muted">Aún no tienes gimnasios asignados</p>
            }
        </main>
    )
}
