import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import {UserProvider} from './contexts/UserContext';

import indexRoute from './routes/indexRoute';
import Layout from './views/Layout';
import publicRoutes from './routes/publicRoutes';
import panelRoutes from './routes/panelRoutes';
import adminRoutes from './routes/adminRoutes';
//import reportWebVitals from './reportWebVitals';

import "moment/locale/es";
import printRoutes from './routes/printRoutes';


const router = createBrowserRouter(
[
    {
        element: <Layout/>,
        children: [...indexRoute, ...publicRoutes, ...panelRoutes, ...printRoutes, ...adminRoutes]
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <UserProvider>
            <RouterProvider router={router}/>
        </UserProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
