import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function Login()
{
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const {Authenticate} = useUser();


    useEffect(() =>
    {
        document.title = "Iniciar sesión | Gymanagr"
    }, []);


    // Form auth
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/auth/login", form).then(response =>
        {
            const JWT = response.data;
            Authenticate(JWT);
            navigate("/mis-gimnasios");
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <main className="container section" style={{maxWidth: "30rem"}}>
            <div className="mb-5 text-center">
                <h2 className="display-6">Iniciar sesión</h2>
            </div>
            
            <div className="p-4 bg-white border rounded-3 shadow">
                <form onSubmit={onFormSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="username">Nombre de usuario <span className="text-danger">*</span></label>
                        <input className="form-control" type="username" id="username" onChange={handleChange} required/>
                        <small className="text-danger">{errors.username}</small>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="password">Contraseña <span className="text-danger">*</span></label>
                        <input className="form-control" type="password" id="password" onChange={handleChange} required/>
                        <small className="text-danger">{errors.password}</small>
                    </div>
                    
                    <button type="submit" className="btn btn-primary fw-bold w-100">Ingresar</button>
                </form>
            </div>
        </main>
    )
}
