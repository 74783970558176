import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";
import PaymentStatus from "../../enums/PaymentStatus";


export default function Sales()
{
    const {managedGym} = useUser();

    const [sales, setSales] = useState([]);
    const [loaded, setLoaded] = useState({});


    useEffect(() =>
    {
        if(!managedGym) return;

        request("GET", `/sales/?gymId=${managedGym.id}`).then(response => 
        {
            setSales(response.data);
            setLoaded(copy => ({...copy, list: true}));
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });

        document.title = `Ventas - ${managedGym.name}`;
    }, [managedGym]);


    return (
        <main>
            <div className="mb-4 d-flex flex-wrap align-items-center justify-content-between gap-2">
                <h3 className="mb-0 fw-semibold">Ventas</h3>

                <Link to={"form"} type="button" className="btn btn-primary fw-semibold">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-plus"></i>
                        <span>Registrar venta</span>
                    </div>
                </Link>
            </div>

            {/*  */}
            {(loaded.list) ?
                <Fragment>
                    {(sales.length > 0) ?
                        <div className="table-responsive" style={{minHeight: "20rem"}}>
                            <table className="table table-sm table-transparent table-bordered table-hover caption-top align-middle">
                                <caption>Lista de ventas</caption>

                                <thead>
                                    <tr className="text-bg-primary">
                                        <th className="text-center text-reset">#</th>
                                        <th className="text-reset">Fecha</th>
                                        <th className="text-reset">Detalles</th>
                                        <th className="text-reset">Total</th>
                                        <th className="text-reset">Pagado</th>
                                        <th className="text-reset">Balance</th>
                                        <th className="text-reset">Estado</th>
                                        <th className="text-reset"></th>
                                    </tr>
                                </thead>
        
                                <tbody className="bg-white">
                                    {sales.map(sale =>
                                    {
                                        return (
                                            <tr key={sale.id}>
                                                <td className="text-nowrap text-center">#{sale.id}</td>
                                                <td>
                                                    <span className="text-capitalize-first">
                                                        {(new Date().toLocaleDateString() === new Date(sale.createdAt).toLocaleDateString()) ?
                                                            (moment(sale.createdAt).calendar())
                                                            :
                                                            (moment(sale.createdAt).format("L HH:mm"))
                                                        }
                                                    </span>
                                                </td>
                                                <td>{sale.details}</td>
                                                <td className="text-nowrap">$ {sale.total.toLocaleString()}</td>
                                                <td className="text-nowrap">$ {sale.paidAmount.toLocaleString()}</td>
                                                <td className="fw-semibold text-nowrap">$ {sale.balance.toLocaleString()}</td>
                                                <td>
                                                    {(sale.paymentStatus.value === PaymentStatus.PAID) &&
                                                        <span className="badge text-bg-success">{sale.paymentStatus.name}</span>
                                                    }
                                                    {(sale.paymentStatus.value === PaymentStatus.PARTIALLY_PAID) &&
                                                        <span className="badge text-bg-warning">{sale.paymentStatus.name}</span>
                                                    }
                                                    {(sale.paymentStatus.value === PaymentStatus.UNPAID) &&
                                                        <span className="badge text-bg-secondary">{sale.paymentStatus.name}</span>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    <Link to={`form?id=${sale.id}`} className="btn btn-sm btn-light" title="Editar venta">
                                                        <i className="fa-regular fa-pen-square"></i>
                                                    </Link>
                                                    {/* <OrderDropdown order={order} onDelete={fetchOrders}> 
        
                                                    </OrderDropdown> */}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="p-4 bg-white border rounded-3">
                            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                                <h5 className="mb-0">Aún no hay ventas registradas</h5>

                                <Link to={"form"} type="button" className="btn btn-primary">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-user-plus"></i>
                                        <span>Registrar venta</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }
                </Fragment>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 