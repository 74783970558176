import {Outlet} from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Login from "../views/public/Login";
import MyGyms from "../views/public/MyGyms";
import NotFound from "../views/public/NotFound";


export const Layout = () => (
    <>
        <Header/>
        <Outlet/>
        <Footer/>
    </>
);

const publicRoutes = 
[
    {
        element: <Layout/>,
        errorElement: <NotFound/>,
        children:
        [
            {path: "login", element: <Login/>},
            {path: "mis-gimnasios", element: <MyGyms/>},
            {path: "404", element: <NotFound/>}
        ]
    }
];

export default publicRoutes;