import {Link, useLocation} from 'react-router-dom';

import {panelLinks} from '../../routes/panelRoutes';
import Authorized from './Authorized';


export default function Sidebar()
{
    const location = useLocation();


    return (
        <div className="collapse collapse-horizontal show me-4" id="collapsePanelSidebar">
            <div className="vstack gap-2">
                {panelLinks.map(link =>
                {
                    const pathname = "/panel/" + link.href + "/";
                    const active = (location.pathname + "/") === pathname;

                    const btnClass = (active) ? ("btn-primary fw-semibold") : ("btn-light");
                    const iconClass = (active) ? ("fa-solid") : ("fa-thin");
                    const collapse = `collapse_${link.href}`;

                    return (
                        <Authorized action={link.action} key={link.href}>
                            {(link.subLinks) ?
                                <div>
                                    <button className="btn btn-light w-100" type="button" data-bs-toggle="collapse" data-bs-target={"#" + collapse} aria-expanded="false" aria-controls={collapse}>
                                        <div className="d-flex align-items-center justify-content-between gap-3">
                                            <div className="hstack gap-2">
                                                <i className={`fa-thin ${link.icon} fa-fw`}></i>
                                                <span>{link.title}</span>
                                            </div>

                                            <i className="fa-regular fa-chevron-down"></i>
                                        </div>
                                    </button>

                                    <div className="collapse ps-4" id={collapse}>
                                        <div className="vstack gap-1">
                                            {/* <Link to={link.href} className={`mt-1 btn btn-sm ${btnClass} w-100 text-start`}>
                                                Ver todos
                                            </Link> */}

                                            {link.subLinks.map(subLink =>
                                            {
                                                const subPathname = "/panel/" + link.href + "/" + subLink.href + "/";
                                                const subActive = (location.pathname + "/") === subPathname;

                                                const subBtnClass = (subActive) ? ("btn-primary fw-semibold") : ("btn-light");

                                                return (
                                                    <Link to={link.href + "/" + subLink.href} className={`btn btn-sm ${subBtnClass} w-100 text-start`} key={subLink.href}>
                                                        {subLink.title}
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                :
                                <Link to={link.href} className={`btn ${btnClass}`}>
                                    <div className="hstack gap-2">
                                        <i className={`${iconClass} ${link.icon} fa-fw`}></i>
                                        <span>{link.title}</span>
                                    </div>
                                </Link>
                            }
                        </Authorized>
                    );
                })}
            </div>
        </div>
    );
}
