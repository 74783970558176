import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {useUser} from "../../contexts/UserContext";
import SubscriptionStatus from "../../enums/SubscriptionStatus";
import Authorized from "./Authorized";


export default function SubscriptionStatusAlert()
{
    const {managedGym} = useUser();
    const [subscription, setSubscription] = useState(null);

    const [text, setText] = useState("");
    const [alertClass, setAlertClass] = useState("");


    useEffect(() =>
    {
        if(!managedGym) return;

        setSubscription(managedGym.subscription);
    }, [managedGym]);


    useEffect(() =>
    {
        if(!subscription) return;

        switch(subscription.status.value)
        {
            case SubscriptionStatus.ACTIVE:
            {
                setText("La suscripción del gimnasio está activa.");
                setAlertClass("alert-success");
                break;
            }
            case SubscriptionStatus.EXPIRED:
            {
                setText(`La suscripción del gimnasio venció hace ${-subscription.daysUntilExpiration} días. Renuévala hoy para poder seguir usando el software.`);
                setAlertClass("alert-danger");
                break;
            }
            default:
            {
                setText("La suscripción del gimnasio venció y ya no está disponible para su uso. Renuévala hoy para poder seguir usando el software.");
                setAlertClass("alert-dark");
                break;
            }
        }
    }, [subscription]);

    
    if(!subscription || subscription.status.value === SubscriptionStatus.ACTIVE) return null;
    return (
        <Authorized action={""}>
            <div className={`alert ${alertClass} alert-dismissible fade show`} role="alert">
                <div className="d-flex align-items-start gap-2">
                    <i className="fa-regular fa-exclamation-circle pt-1"></i>
                    <div>
                        <p className="mb-1">{text}</p>
                        <Link to={"/panel/suscripcion"} className="btn btn-primary">Ir a suscripción</Link>
                    </div>
                </div>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </Authorized>
    );
}