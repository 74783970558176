import {Link} from "react-router-dom";


export default function Footer()
{
    return (
        <footer className="py-5 text-white" style={{backgroundColor: "#111"}}>
            <div className="container">
                <div className="mb-3 border-top"></div>

                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-4">
                    <p className="mb-0 fs-5 text-center">
                        <Link to="/" className="fw-semibold text-decoration-none text-white">Gymanagr</Link>
                        <span className="text-nowrap"> © 2023 Company, Inc</span>
                    </p>

                    <ul className="mb-0 d-flex gap-4 list-unstyled">
                        <li><a href="https://twitter.com/gymanagr" className="display-6 text-decoration-none text-white" target="_blank" rel="noreferrer" title="Twitter"><i className="fa-brands fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/gymanagr" className="display-6 text-decoration-none text-white" target="_blank" rel="noreferrer" title="Instagram"><i className="fa-brands fa-instagram"></i></a></li>
                        <li><a href="https://www.tiktok.com/@gymanagr" className="display-6 text-decoration-none text-white" target="_blank" rel="noreferrer" title="TikTok"><i className="fa-brands fa-tiktok"></i></a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}
