import {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";


export default function SupplierForm()
{
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [loaded, setLoaded] = useState({});

    // Obtener datos del proveedor
    useEffect(() =>
    {
        setLoaded(copy => ({...copy, form: true}));
    }, [params]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", "/admin/gyms/add", form).then(() => 
        {
            Swal.fire(
            {
                title: "Gimnasio añadido",
                icon: "success",
                timer: 3000
            })
            .then(() => navigate(-1));
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <Fragment>
            {(loaded.form) ?
                <main>
                    <div className="mb-3">
                        <h4 className="mb-0">Nuevo gimnasio</h4>
                    </div>
                    
                    <div className="p-4 bg-white border rounded-3 shadow">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="name">Nombre del gimnasio <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="name" value={form.name || ""} onChange={handleChange} required/>
                                <small className="text-danger">{errors.name}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="ownerUsername">Nombre de usuario del dueño <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="ownerUsername" value={form.ownerUsername || ""} onChange={handleChange}/>
                                <small className="text-danger">{errors.ownerUsername}</small>
                            </div>

                            <div className="hstack gap-2">
                                <button type="submit" className="btn btn-primary fw-bold">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-plus"></i>
                                        <span>Crear gimnasio</span>
                                    </div>
                                </button>
                                <Link to={"./.."} className="btn btn-secondary">Volver</Link>
                            </div>
                        </form>
                    </div>
                </main>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </Fragment>
    );
} 