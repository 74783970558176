import {useEffect, useState} from "react";

import {request} from "../../services/AxiosService";
import { useUser } from "../../contexts/UserContext";
import SubscriptionStatus from "../../enums/SubscriptionStatus";
import moment from "moment";


export default function Subscription()
{
    const {managedGym} = useUser();

    const [subscription, setSubscription] = useState({});
    const [loaded, setLoaded] = useState({});

    
    // Obtener datos
    useEffect(() =>
    {
        if(!managedGym) return;
        
        request("GET", `/gyms/subscription?id=${managedGym.id}`).then(response => 
        {
            setSubscription(response.data);
            setLoaded(copy => ({...copy, list: true}));
        });
    }, [managedGym]);


    return (
        <main>
            <div className="mb-3">
                <h3 className="mb-0 fw-semibold">Suscripción</h3>
            </div>

            {(loaded.list) ?
                <div className="p-4 bg-white border rounded-3 shadow" style={{maxWidth: "35rem"}}>
                    <h3>Detalles</h3>

                    <hr />

                    <div className="vstack gap-2">
                        <div className="hstack justify-content-between gap-4">
                            <p className="mb-0 fw-semibold">Estado</p>
                            <p className="mb-0 fs-5 fw-semibold">
                                {(subscription.status.value === SubscriptionStatus.ACTIVE) && <span className="text-success">{subscription.status.name}</span>}
                                {(subscription.status.value === SubscriptionStatus.PENDING) && <span className="text-warning">{subscription.status.name}</span>}
                                {(subscription.status.value === SubscriptionStatus.EXPIRED) && <span className="text-danger">{subscription.status.name}</span>}
                            </p>
                        </div>

                        <div className="hstack justify-content-between gap-4">
                            <p className="mb-0 fw-semibold">Fecha de inicio</p>
                            <p className="mb-0">{moment(subscription.startedAt).format("LL")}</p>
                        </div>

                        <div className="hstack justify-content-between gap-4">
                            <p className="mb-0 fw-semibold">Próximo pago</p>
                            <p className="mb-0">{moment(subscription.expiresAt).format("LL")}</p>
                        </div>

                        {(subscription.daysUntilExpiration >= 0) ?
                            <div className="d-flex align-items-start justify-content-between gap-2">
                                <span className="fw-semibold">Vence en</span>
                                <span>{subscription.daysUntilExpiration} días</span>
                            </div>
                            :
                            <div className="d-flex align-items-start justify-content-between gap-2 fw-semibold text-danger">
                                <span>Venció hace</span>
                                <span>{-subscription.daysUntilExpiration} días</span>
                            </div>
                        }
                    </div>

                    <hr />

                    <div>
                        <p className="fw-semibold text-muted">Historial de pagos</p>

                        {(subscription.payments.length) ?
                            <div className="table-responsive">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Monto</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {subscription.payments.map(payment =>
                                        {
                                            return (
                                                <tr key={payment.id}>
                                                    <td className="text-muted">{moment(payment.date).format("lll")}</td>
                                                    <td className="fw-semibold text-success text-nowrap">$ {payment.amount.toLocaleString()}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="mb-0 alert alert-secondary show" role="alert">
                                Aún no hay pagos registrados
                            </div>
                        }
                    </div>
                </div>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 