import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function Products()
{
    const {managedGym} = useUser();

    const [products, setProducts] = useState([]);
    const [loaded, setLoaded] = useState({});


    useEffect(() =>
    {
        if(!managedGym) return;

        request("GET", `/products/?gymId=${managedGym.id}`).then(response => 
        {
            setProducts(response.data);
            setLoaded(copy => ({...copy, list: true}));
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });

        document.title = `Productos - ${managedGym.name}`;
    }, [managedGym]);


    return (
        <main>
            <div className="mb-4 d-flex flex-wrap align-items-center justify-content-between gap-2">
                <h3 className="mb-0 fw-semibold">Productos</h3>

                <Link to={"form"} type="button" className="btn btn-primary fw-semibold">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-plus"></i>
                        <span>Añadir producto</span>
                    </div>
                </Link>
            </div>

            {/*  */}
            {(loaded.list) ?
                <Fragment>
                    {(products.length > 0) ?
                        <div className="table-responsive">
                            <table className="table table-transparent table-bordered table-hover caption-top align-middle">
                                <caption>Lista de productos</caption>

                                <thead>
                                    <tr className="text-bg-primary">
                                        <th className="text-reset">Nombre</th>
                                        <th className="text-reset">Precio unitario</th>
                                        <th className="text-reset">Stock</th>
                                        <th className="text-reset">Descripción</th>
                                        <th className="text-reset"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {products.map(product =>
                                    {
                                        return (
                                            <tr key={product.id}>
                                                <td className="text-nowrap">
                                                    <div className="hstack gap-2">
                                                        <i className="fa-solid fa-box text-primary"></i>
                                                        <span>{product.name}</span>
                                                    </div>
                                                </td>
                                                <td className="text-nowrap fw-semibold">$ {product.unitPrice.toLocaleString()}</td>
                                                <td className="text-nowrap">{product.stock} {product.measurement.shortName}</td>
                                                <td>{product.description}</td>
                                                <td className="text-center">
                                                    <Link to={`form?id=${product.id}`} className="btn btn-sm btn-light" type="button" title="Editar producto">
                                                        <i className="fa-regular fa-pen-square"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="p-4 bg-white border rounded-3">
                            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                                <h5 className="mb-0">Aún no hay productos</h5>

                                <Link to={"form"} type="button" className="btn btn-primary">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-user-plus"></i>
                                        <span>Añadir producto</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }
                </Fragment>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 