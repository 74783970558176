import {useUser} from "../../contexts/UserContext";
import {hasPermission} from "../../services/UtilsService";


const Authorized = ({action, children}) =>
{
    const {user, managedGym} = useUser();

    return (hasPermission(user, managedGym, action) === true) ? (children) : (null);
};

export default Authorized;