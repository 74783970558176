import {Outlet} from "react-router-dom";

import UserRole from "../enums/UserRole";
import ProtectedRoute from "../contexts/ProtectedRoute";

import NotFound from "../views/public/NotFound";
import ClientPayments from "../views/prints/ClientPayments";


const printRoutes = 
[
    {
        path: "/imprimir/",
        element: <ProtectedRoute role={UserRole.PARTNER}/>,
        children:
        [{
            element: <Outlet/>,
            errorElement: <NotFound/>,
            children:
            [
                {path: "pagos", element: <ClientPayments/>},
            ]
        }]
    }
];

export default printRoutes;
