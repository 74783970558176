import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment";

import SubscriptionStatus from "../../enums/SubscriptionStatus";


export default function ClientCard({client})
{
    const [color, setColor] = useState("");

    useEffect(() =>
    {
        switch(client.subscriptionStatus.value) 
        {
            case SubscriptionStatus.ACTIVE:
                setColor("primary");
                break;
            case SubscriptionStatus.PENDING:
                setColor("warning");
                break;
            default:
                setColor("muted");
                break;
        }
    }, [client]);


    return (
        <div className="p-3 bg-white border rounded shadow-sm">
            <div className="mb-3 d-flex align-items-center justify-content-between gap-3">
                <Link to={`membresia?id=${client.id}`} className="link-dark link-underline-opacity-0">
                    <div className="hstack gap-2">
                        <div className="p-2">
                            <i className={`fa-solid fa-user-circle fa-2xl text-${color}`}></i>
                        </div>

                        <div>
                            <h3 className="mb-0 fs-5 fw-semibold">{client.name}</h3>
                            <p className="mb-0 small text-muted">{client.subscriptionStatus.name}</p>
                        </div>
                    </div>
                </Link>

                <div className="dropdown">
                    <button className="btn btn-light bg-white rounded-circle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-ellipsis-vertical px-1"></i>
                    </button>

                    <ul className="dropdown-menu">
                        <li>
                            <Link className="dropdown-item" to={`membresia?id=${client.id}`}>
                                <div className="hstack gap-2">
                                    <i className="fa-regular fa-money-check-dollar fa-fw text-primary"></i>
                                    <span>Ver membresía</span>
                                </div>
                            </Link>
                            <Link className="dropdown-item" to={`editar?id=${client.id}`}>
                                <div className="hstack gap-2">
                                    <i className="fa-regular fa-pen fa-fw text-primary"></i>
                                    <span>Editar cliente</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="vstack gap-1">
                <div className="d-flex align-items-center justify-content-between gap-2">
                    <p className="mb-0 text-muted">{(client.daysUntilExpiration >= 0) ? ("Próximo pago") : ("Fecha de pago")}</p>
                    <p className="mb-0 fw-semibold text-end">{moment(client.expiresAt).format("DD MMM yyyy")}</p>
                </div>

                <div className="d-flex align-items-center justify-content-between gap-2">
                    <p className="mb-0 text-muted">{(client.daysUntilExpiration >= 0) ? ("Vence en") : ("Venció hace")}</p>
                    <p className="mb-0 fw-semibold text-end">{Math.abs(client.daysUntilExpiration)} días</p>
                </div>
            </div> 
        </div>
    );
}