import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";


export function request(method, url, data = {}, isMultipart = false)
{
    const JWT = localStorage.getItem("JWT") || null;
    const bearer = (JWT) ? (`Bearer ${JWT}`) : (null);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if(isMultipart)
    {
        const form = new FormData();
        for(const key in data) {
            form.append(key, data[key]);
        }
        data = form;
    }

    return axios(
    {
        method: method,
        headers: 
        {
            Authorization: bearer, 
            "Client-Timezone":  timeZone,
            ...((isMultipart) ? {"Content-Type": "multipart/form-data"} : {})
        },
        url: url,
        data: data
    });
}