import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";


export default function Users()
{
    const [users, setUsers] = useState(null);
    const [loaded, setLoaded] = useState({});


    useEffect(() =>
    {
        request("GET", "/admin/users").then(response => 
        {
            setUsers(response.data);
            setLoaded(copy => ({...copy, list: true}));
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, []);


    return (
        <main className="container my-5">
            <div className="mb-4 d-flex flex-wrap align-items-center justify-content-between gap-2">
                <h3 className="mb-0 fw-semibold">Usuarios</h3>

                <Link to={"form"} type="button" className="btn btn-primary fw-semibold">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-user-plus"></i>
                        <span>Nuevo usuario</span>
                    </div>
                </Link>
            </div>

            {/*  */}
            {(loaded.list) ?
                <div className="table-responsive">
                    <table className="table table-transparent table-bordered align-middle">
                        <thead>
                            <tr className="text-bg-primary">
                                <th className="text-reset">#</th>
                                <th className="text-reset">Nombre completo</th>
                                <th className="text-reset">Username</th>
                                <th className="text-reset">Rol</th>
                                <th className="text-reset"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {users.map(user =>
                            {
                                return (
                                    <tr key={user.id}>
                                        <td># {user.id}</td>
                                        <td className="text-nowrap">
                                            <div className="hstack gap-2">
                                                <i className="fa-solid fa-user text-primary"></i>
                                                <span>{user.name} {user.lastName}</span>
                                            </div>
                                        </td>
                                        <td>@{user.username}</td>
                                        <td className="text-nowrap">{user.role.name}</td>
                                        <td className="text-center">
                                            <Link to={`form?id=${user.id}`} className="btn btn-sm btn-light" title="Editar usuario">
                                                <i className="fa-regular fa-pen-square"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <Fragment>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </Fragment>
            }
        </main>
    );
} 