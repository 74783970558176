import {Outlet} from "react-router-dom";

import ProtectedRoute from "../contexts/ProtectedRoute";
import UserRole from "../enums/UserRole";

import Header from "../components/Header";
import Footer from "../components/Footer";
import NotFound from "../views/public/NotFound";
import Gyms from "../views/admin/Gyms";
import GymForm from "../views/admin/GymForm";
import UserForm from "../views/admin/UserForm";
import Users from "../views/admin/Users";



const Layout = () => (
    <>
        <Header/>

        <div className="container-xxl my-5">
            <Outlet/>
        </div>

        <Footer/>
    </>
);

const adminRoutes = 
[
    {
        path: "/admin",
        element: <ProtectedRoute role={UserRole.ADMIN}/>,
        children:
        [{
            element: <Layout/>,
            errorElement: <NotFound/>,
            children: 
            [
                {path: "gimnasios", element: <Gyms/>},
                {path: "gimnasios/form", element: <GymForm/>},
                {path: "usuarios", element: <Users/>},
                {path: "usuarios/form", element: <UserForm/>},
            ]
        }]
    }
];

export default adminRoutes;