import {Outlet} from "react-router-dom";

import UserRole from "../enums/UserRole";
import ProtectedRoute from "../contexts/ProtectedRoute";

import PanelHeader from "../components/panel/PanelHeader";
import Sidebar from "../components/panel/Sidebar";
import OffcanvasSidebar from "../components/panel/OffcanvasSidebar";
import SubscriptionStatusAlert from "../components/panel/SubscriptionStatusAlert";

import NotFound from "../views/public/NotFound";

import Clients from "../views/panel/Clients";
import ClientAddForm from "../views/panel/ClientAddForm";
import PlanForm from "../views/panel/PlanForm";
import Plans from "../views/panel/Plans";
import ClientEditForm from "../views/panel/ClientEditForm";
import ClientMembership from "../views/panel/ClientMembership";
import Products from "../views/panel/Products";
import ProductsForm from "../views/panel/ProductForm";
import Sales from "../views/panel/Sales";
import SaleForm from "../views/panel/SaleForm";
import Settings from "../views/panel/Settings";
import Expenses from "../views/panel/Expenses";
import ExpenseForm from "../views/panel/ExpenseForm";
import Summary from "../views/panel/Summary";
import Subscription from "../views/panel/Subscription";
import Reports from "../views/panel/Reports";


const Layout = () => (
    <div className="bg-light" style={{minHeight: "100vh"}}>
        <PanelHeader/>

        <div className="container-xxl py-3 py-lg-4">
            <div className="d-flex flex-column flex-lg-row">
                <div className="d-none d-lg-block">
                    <Sidebar></Sidebar>
                </div>

                <div className="d-block d-lg-none">
                    <OffcanvasSidebar></OffcanvasSidebar>
                </div>

                <div className="flex-fill" style={{minWidth: 0}}>
                    <SubscriptionStatusAlert/>

                    <Outlet/>
                    
                    <div className="py-5"></div>
                </div>
            </div>
        </div>
    </div>
);

const panelRoutes = 
[
    {
        path: "/panel/",
        element: <ProtectedRoute role={UserRole.PARTNER}/>,
        children:
        [{
            element: <Layout/>,
            errorElement: <NotFound/>,
            children:
            [
                {path: "clientes", element: <Clients/>},
                {path: "clientes/añadir", element: <ClientAddForm/>},
                {path: "clientes/editar", element: <ClientEditForm/>},
                {path: "clientes/membresia", element: <ClientMembership/>},
                {path: "ventas", element: <Sales/>},
                {path: "ventas/form", element: <SaleForm/>},
                {path: "gastos", element: <Expenses/>},
                {path: "gastos/form", element: <ExpenseForm/>},
                {path: "productos", element: <Products/>},
                {path: "productos/form", element: <ProductsForm/>},
                {path: "planes", element: <Plans/>},
                {path: "planes/form", element: <PlanForm/>},
                {path: "resumen", element: <Summary/>},
                {path: "reportes", element: <Reports/>},
                {path: "suscripcion", element: <Subscription/>},
                {path: "ajustes", element: <Settings/>},
            ]
        }]
    }
];
export default panelRoutes;


export const panelLinks =
[
    {title: "Clientes",         href: "clientes",       icon: "fa-users"},
    // {title: "Ventas",           href: "ventas",         icon: "fa-sack-dollar"},
    // {title: "Gastos",           href: "gastos",         icon: "fa-money-bills"},
    // {title: "Productos",        href: "productos",      icon: "fa-box"},
    {title: "Planes",           href: "planes",         icon: "fa-comments-dollar"},
    // {title: "Resumen",          href: "resumen",        icon: "fa-chart-line"},
    {title: "Reportes",         href: "reportes",       icon: "fa-file-arrow-down"},
    {title: "Suscripción",      href: "suscripcion",    icon: "fa-money-check"},
    {title: "Ajustes",          href: "ajustes",        icon: "fa-gear"},
    // {
    //     title: "Personal",
    //     href: "personal",
    //     icon: "fa-user-tie",
    //     subLinks:
    //     [
    //         {title: "Entrenadores", href: "entrenadores"},
    //         {title: "Recepcionistas", href: "recepcionistas"},
    //     ]
    // },
];
