import {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Swal from "sweetalert2";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function ClientEditForm()
{
    const {managedGym} = useUser();

    const [params] = useSearchParams();
    const navigate = useNavigate();

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [loaded, setLoaded] = useState({});

    // Obtener datos
    useEffect(() =>
    {
        const id = params.get("id");
        request("GET", `/clients/edit?id=${id}`).then(response => 
        {
            setForm(response.data);
            setLoaded(copy => ({...copy, form: true}));
        });
    }, [params]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();

        request("POST", `/clients/edit?gymId=${managedGym.id}`, form).then(() => 
        {
            Swal.fire(
            {
                title: "Cliente editado",
                icon: "success",
                timer: 3000
            })
            .then(() => navigate(-1));
        })
        .catch(error =>
        {
            if(error.response.data) 
            {
                const {errors} = error.response.data;
                setErrors(errors);
            }
        });
    };


    return (
        <Fragment>
            {(loaded.form) ?
                <main>
                    <div className="mb-3">
                        <Link to={"./../"} className="btn btn-light mb-3 bg-white border fw-semibold shadow-sm">
                            <div className="hstack gap-2">
                                <i className="fa-regular fa-chevron-left"></i>
                                <span>Volver</span>
                            </div>
                        </Link>

                        <h4 className="mb-0">Editar cliente</h4>
                    </div>
                    
                    <div className="p-4 bg-white border rounded-3 shadow">
                        <form onSubmit={onFormSubmit} autoComplete="off">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="name">Nombre completo <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="name" value={form.name || ""} onChange={handleChange} required/>
                                <small className="text-danger">{errors.name}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="phone">Número de teléfono</label>
                                <input className="form-control" type="tel" id="phone" value={form.phone || ""} onChange={handleChange}/>
                                <small className="text-danger">{errors.phone}</small>
                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="documentNumber">Número de documento</label>
                                <input className="form-control" type="number" id="documentNumber" value={form.documentNumber || ""} onChange={handleChange}/>
                                <small className="text-danger">{errors.documentNumber}</small>
                            </div>

                            <div className="hstack gap-2">
                                <button type="submit" className="btn btn-primary fw-bold">Guardar datos</button>

                                <Link to={`./../membresia?id=${form.id}`} className="btn btn-success">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-address-card"></i>
                                        <span>Ver membresía</span>
                                    </div>
                                </Link>
                            </div>
                        </form>
                    </div>
                </main>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </Fragment>
    );
} 