import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";

import {useUser} from "../../contexts/UserContext";


export default function PanelHeader()
{
    const navigate = useNavigate();

    const {user, managedGym, Logout} = useUser();


    useEffect(() =>
    {
        if(!managedGym) 
        {
            navigate("/mis-gimnasios");
            return;
        }
    }, [managedGym, navigate]);


    const onLogout = () =>
    {
        Logout();
        navigate("/");
    };


    if(!managedGym || !user) return null;
    return (
        <header className="py-4 text-bg-primary">
            <div className="container-xxl">
                <div className="d-flex align-items-center justify-content-between gap-4">
                    <div className="hstack gap-3">
                        <div className="d-none d-lg-block">
                            <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePanelSidebar" aria-expanded="false" aria-controls="collapsePanelSidebar">
                                <i className="fa-regular fa-bars"></i>
                            </button>
                        </div>

                        <div className="d-block d-lg-none">
                            <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasPanelSidebar" aria-controls="offcanvasPanelSidebar">
                                <i className="fa-regular fa-bars"></i>
                            </button>
                        </div>

                        <Link to={"/panel/clientes"} className="link-light text-decoration-none" title="Ir a inicio">
                            <div className="hstack gap-2">
                                {(managedGym.logoUrl) &&
                                    <img className="img-fluid shadow rounded" src={managedGym.logoUrl} alt="Logo" style={{maxHeight: "2rem"}}/>
                                }

                                <h2 className="mb-0 lh-1">{managedGym.name}</h2>
                            </div>
                        </Link>
                    </div>

                    <div className="dropdown">
                        <button className="btn-unstyled text-reset" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="hstack gap-2">
                                <i className="fa-solid fa-user-circle fs-1"></i>
                                <i className="fa-solid fa-chevron-down fa-sm"></i>
                            </div>
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-header">
                                Bienvenido, {user.name}
                            </li>
                            {/* <li>
                                <Link to={"/clubes/" + managedGym.slug} className="dropdown-item">
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <span>Ir al club</span>
                                        <i className="fa-regular fa-arrow-up-right-from-square"></i>  
                                    </div>
                                </Link>
                            </li>
                            <li className="dropdown-divider"></li> */}
                            <li>
                                <button className="dropdown-item" type="button" onClick={() => onLogout()}>
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <span>Cerrar sesión</span>
                                        <i className="fa-regular fa-right-from-bracket"></i>  
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div> 
                </div>
            </div>
        </header>
    );
}