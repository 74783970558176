import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import moment from "moment";

import {request} from "../../services/AxiosService";


export default function ClientPayments()
{
    const [params] = useSearchParams();

    const [data, setData] = useState(null);


    useEffect(() =>
    {
        const gymId = params.get("gymId"); // Obtener id desde la url
        const from = params.get("from");
        const to = params.get("to");

        request("GET", `/clients/payments/print?gymId=${gymId}&from=${from}&to=${to}`).then(response => 
        {
            setData(response.data);

            document.title = `PAGOS-${moment().format("yyyy-MM-DD")}`;
        });
    }, [params]);

    
    // Imprimir al cargar todos los datos
    useEffect(() =>
    {
        if(!data) return;

        window.print();
    }, [data]);


    if(!data) return <div>Cargando...</div>
    return (
        <main className="p-1">
            <div className="mb-3 d-flex align-items-start justify-content-between gap-2">
                <div className="hstack gap-3">
                    {(data.gym.logoUrl) &&
                        <div className="p-2">
                            <img src={data.gym.logoUrl} alt="Logo" style={{width: "100%", maxWidth: "4rem", objectFit: "contain"}}/>
                        </div>
                    }

                    <div>
                        <h1 className="mb-0 fw-bold fs-3 text-uppercase">{data.gym.name}</h1>
                        {(data.gym.address) && <p className="mb-0 text-muted">{data.gym.address}</p>}
                        {(data.gym.phone) && <p className="mb-0 text-muted">Teléfono: {data.gym.phone}</p>}
                    </div>                    
                </div>

                <div className="text-end">
                    <p className="mb-0 fw-bold text-uppercase">Reporte de pagos</p>
                    <p className="mb-0 text-muted">{moment(data.from).format("DD MMM yyyy")} - {moment(data.to).format("DD MMM yyyy")}</p>
                </div>
            </div>

            <hr className="my-4"/>

            <div className="mb-3">
                <table className="table table-bordered table-light table-striped caption-top">
                    <caption className="pt-0">{data.payments.length} pagos registrados</caption>

                    <thead>
                        <tr className="fw-bold">
                            <td>Fecha</td>
                            <td>Cliente</td>
                            <td>Monto</td>
                        </tr>
                    </thead>

                    <tbody>
                        {data.payments.map(payment =>
                        {
                            return (
                                <tr key={payment.id}>
                                    <td>{moment(payment.date).format("DD/MM/yyyy")}</td>
                                    <td>{payment.client.name}</td>
                                    <td className="fw-bold">$ {payment.amount.toLocaleString()}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="hstack justify-content-end gap-3">
                <h6 className="mb-0 fw-normal">TOTAL</h6>
                <h3 className="mb-0 fw-bold">$ {data.total.toLocaleString()}</h3>
            </div>
        </main>
    );
} 