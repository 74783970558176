import React, {useEffect, useState} from "react";
import moment from "moment";

import {useUser} from "../../contexts/UserContext";
import { print } from "../../services/UtilsService";


export default function Reports()
{
    const {managedGym} = useUser();

    const fromDate = new Date().setDate(1);
    const toDate = new Date();

    const [form, setForm] = useState({from: moment(fromDate).format("yyyy-MM-DD"), to: moment(toDate).format("yyyy-MM-DD")});
    const [errors, setErrors] = useState({});


    // Cuando carga la vista
    useEffect(() =>
    {
        if(!managedGym) return;

        document.title = `Resumen - ${managedGym.name}`;
    }, [managedGym]);


    // Form
    const handleChange = e =>
    {
        const {id, value} = e.target;
        setForm((copy) => ({...copy, [id]: value}));
        setErrors((copy) => ({...copy, [id]: null}));
    };

    const onFormSubmit = e =>
    {
        e.preventDefault();
        
        // Verificar formulario
        if(form.from === "") setErrors({...errors, from: "Indica la fecha de inicio"});
        if(form.to === "") setErrors({...errors, to: "Indica la fecha de finalización"});
        if(new Date(form.from) > new Date(form.to)) setErrors({...errors, from: "La fecha de inicio debe ser anterior a la fecha de finalización"});
        if(Object.keys(errors).length > 0) return; // Si el form tiene errores, detener script

        // Imprimir
        print(`/imprimir/pagos?gymId=${managedGym.id}&from=${form.from}&to=${form.to}`);
    };


    return (
        <main>
            <div className="mb-4 d-flex flex-wrap align-items-center justify-content-between gap-2">
                <h3 className="mb-0 fw-semibold">Reportes</h3>
            </div>

            <div className="p-4 bg-white border rounded shadow">
                <form onSubmit={onFormSubmit} noValidate>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="from">Desde <span className="text-danger">*</span></label>
                        <input className="form-control" type="date" id="from" value={form.from || ""} onChange={handleChange} required/>
                        <small className="text-danger">{errors.from}</small>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="to">Hasta <span className="text-danger">*</span></label>
                        <input className="form-control" type="date" id="to" value={form.to || ""} onChange={handleChange} required/>
                        <small className="text-danger">{errors.to}</small>
                    </div>

                    <button className="btn btn-primary rounded-0 fw-bold" type="submit">
                        <div className="hstack gap-2">
                            <i className="fa-regular fa-file-lines"></i>
                            <span>Generar reporte</span>
                        </div>
                    </button>
                </form>
            </div>    
        </main>
    );
} 