import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useUser} from '../contexts/UserContext';
import UserRole from '../enums/UserRole';


export default function Navbar()
{
    const navigate = useNavigate();

    const {user, Logout} = useUser();


    const onLogout = () =>
    {
        Logout();
        navigate("/");
    };


    return (
        <React.Fragment>
            {(user) ?
                <div>
                    <button className="btn-unstyled text-reset" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <div className="hstack gap-2">
                            <i className="fa-solid fa-user-circle fs-1"></i>
                            <i className="fa-solid fa-chevron-down fa-sm"></i>
                        </div>
                    </button>

                    <div className="offcanvas offcanvas-end text-bg-dark" tabIndex="-1" id="offcanvasNavbar">
                        <div className="offcanvas-header justify-content-between">
                            <div className="hstack gap-3">
                                <i className="fa-thin fa-user-circle fa-2xl text-primary"></i>

                                <div>
                                    <p className="mb-0">{user.name} {user.surname}</p>
                                    <p className="mb-0 small fw-semibold">{user.email}</p>
                                </div>
                            </div>

                            <button className="p-2 btn-unstyled text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                <i className="fa-solid fa-x"></i>
                            </button>
                        </div>

                        <div className="offcanvas-body">
                            <div className="vstack gap-2">
                                {/* <Link to={"/perfil/cuenta"} className="fw-semibold fs-5 text-decoration-none text-reset">
                                    <div className="px-3 py-2 text-bg-primary-hover rounded hstack gap-2">
                                        <i className="fa-solid fa-user fa-fw fa-sm"></i>
                                        <span>Perfil</span>
                                    </div>
                                </Link>

                                <Link to={"/perfil/reservas"} className="fw-semibold fs-5 text-decoration-none text-reset">
                                    <div className="px-3 py-2 text-bg-primary-hover rounded hstack gap-2">
                                        <i className="fa-solid fa-calendar-check fa-fw fa-sm"></i>
                                        <span>Mis reservas</span>
                                        {(user.activeReservations > 0) &&
                                            <span className="ms-2 badge bg-primary">
                                                {user.activeReservations}
                                                <span className="visually-hidden">Próximos partidos</span>
                                            </span>
                                        }
                                    </div>
                                </Link> */}

                                {(user.roles.includes(UserRole.PARTNER)) &&
                                    <React.Fragment>
                                        <hr className="my-0"/>

                                        <div className="px-3">
                                            <p className="mb-0 text-light text-opacity-50 ">Panel de control</p>
                                        </div>

                                        <Link to="/mis-gimnasios" className="fw-semibold fs-5 text-decoration-none text-reset">
                                            <div className="px-3 py-2 text-bg-primary-hover rounded hstack gap-2">
                                                <i className="fa-solid fa-buildings fa-fw fa-sm"></i>
                                                <span>Mis gimnasios</span>
                                            </div>
                                        </Link>
                                    </React.Fragment>
                                }

                                {(user.roles.includes(UserRole.ADMIN)) &&
                                    <React.Fragment>
                                        <hr className="my-0"/>

                                        <div className="px-3">
                                            <p className="mb-0 text-light text-opacity-50 ">Administración</p>
                                        </div>

                                        <Link to={"/admin/gimnasios"} className="fw-semibold fs-5 text-decoration-none text-reset">
                                            <div className="px-3 py-2 text-bg-primary-hover rounded hstack gap-2">
                                                <i className="fa-solid fa-buildings fa-fw fa-sm"></i>
                                                <span>Gimnasios</span>
                                            </div>
                                        </Link>

                                        <Link to={"/admin/usuarios"} className="fw-semibold fs-5 text-decoration-none text-reset">
                                            <div className="px-3 py-2 text-bg-primary-hover rounded hstack gap-2">
                                                <i className="fa-solid fa-users fa-fw fa-sm"></i>
                                                <span>Usuarios</span>
                                            </div>
                                        </Link>
                                    </React.Fragment>
                                }

                                <hr className="my-0"/>

                                <button className="btn-unstyled w-100 fw-semibold fs-5 text-decoration-none text-reset" onClick={() => onLogout()}>
                                    <div className="px-3 py-2 text-bg-primary-hover rounded hstack">
                                        <span>Cerrar sesión</span>
                                    </div>
                                </button>
                            </div> 
                        </div>
                    </div>
                </div>
                :
                <div className="align-self-center hstack gap-2">
                    <Link to={"/login"} className="btn btn-primary fw-semibold">
                        <div className="hstack gap-2">
                            <i className="fa-regular fa-user"></i>
                            <span className="d-none d-md-block">Acceder</span>
                        </div>
                    </Link>
                </div>
            }
        </React.Fragment>
    );
}
