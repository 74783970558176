import {Outlet} from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Login from "../views/public/Login";


const Layout = () => (
    <>
        <Header/>
        <Outlet/>
        <Footer/>
    </>
);

const indexRoute = 
[
    {
        element: <Layout/>,
        children:
        [
            {path: "/", element: <Login/>}
        ]
    }
];

export default indexRoute;