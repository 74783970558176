import {createContext, useCallback, useContext, useEffect, useState} from "react";

import {request} from "../services/AxiosService";

const UserContext = createContext();


export const UserProvider = ({children}) => 
{
    const [JWT, setJWT] = useState(() => localStorage.getItem("JWT"));
    const [user, setUser] = useState(null);
    const [redirectPath, setRedirectPath] = useState("/");
    
    const [managedGym, setManagedGym] = useState(() => JSON.parse(localStorage.getItem("managedGym")));


    // Obtener la informacion del usuario, tal como su nombre, roles, gimnasios que maneja, etc
    const fetchUser = useCallback(() =>
    {
        if(!JWT) return; 
        
        request("GET", "/auth/me").then(response => {
            setUser(response.data); // Guardar los datos del usuario
        })
        .catch(() => { // Ocurrió un problema con las credenciales del usuario
            Logout();
        });
    }, [JWT]);


    // Funcion llamada cuando el usuario se registra o loguea
    const Authenticate = (token) => 
    {
        localStorage.setItem("JWT", token); // Almacenar el token en el navegador
        setJWT(token); //
        setUser(null); // Limpiar los datos del usuario, ya que se actualizaran cuando se actualice el componente JWT
    };

    // Cerrar sesion del usuario
    const Logout = () => 
    {
        localStorage.removeItem("JWT"); // Eliminar el token almacenado en el navegador
        setJWT(null); // Limpiar datos
        setUser(null);
    };

    // Cuando el JWT cambia, obtener la información del usuario
    useEffect(() => 
    {
        if(JWT) fetchUser();
    }, [JWT, fetchUser]);

    // Establecer el gimnasio que está manejando el usuario
    const manageGym = (gym) => 
    {
        localStorage.setItem("managedGym", JSON.stringify(gym));
        setManagedGym(gym);
    };


    return (
        <UserContext.Provider value={{user, redirectPath, JWT, setRedirectPath, Authenticate, Logout, managedGym, manageGym}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () =>
{
    const context = useContext(UserContext);
    if(!context) throw new Error("useUser must be used within a UserProvider");
    
    return context;
}
