import {useCallback, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import moment from "moment";

import {request} from "../../services/AxiosService";
import ClientPaymentModal from "../../components/panel/ClientPaymentModal";
import ClientMembershipModal from "../../components/panel/ClientMembershipModal";
import SubscriptionStatus from "../../enums/SubscriptionStatus";


export default function ClientMembership()
{
    const [params] = useSearchParams();

    const [client, setClient] = useState({});
    const [loaded, setLoaded] = useState({});
    const [color, setColor] = useState("");


    // Obtener membresía del cliente
    const fetchMembership = useCallback(() =>
    {
        setLoaded(copy => ({...copy, form: false}));

        const id = params.get("id");
        request("GET", `/clients/membership?id=${id}`).then(response => 
        {
            setClient(response.data);
            setLoaded(copy => ({...copy, form: true}));
        });
    }, [params]);


    // Obtener datos
    useEffect(() =>
    {
        fetchMembership();
    }, [fetchMembership]);


    //
    useEffect(() =>
    {
        if(client.subscriptionStatus)
        {
            switch(client.subscriptionStatus.value) 
            {
                case SubscriptionStatus.ACTIVE:
                    setColor("success");
                    break;
                case SubscriptionStatus.PENDING:
                    setColor("warning");
                    break;
                default:
                    setColor("dark");
                    break;
            }
        }
    }, [client]);


    return (
        <main>
            <div className="mb-3">
                <Link to={"./../"} className="btn btn-light mb-3 bg-white border fw-semibold shadow-sm">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-chevron-left"></i>
                        <span>Volver</span>
                    </div>
                </Link>
            </div>

            {(loaded.form) ?
                <div>
                    <div className="mb-3 border rounded-3 shadow overflow-hidden">
                        <div className="p-4 text-bg-dark">
                            <div className="d-flex align-items-center justify-content-between gap-3">
                                <div className="hstack gap-3">
                                    <i className="fa-solid fa-user-circle fs-1"></i>
                                    <div>
                                        <h2 className="mb-0 fs-5 fw-bold">{client.name}</h2>
                                        <small className="text-light"># {client.id.toString().padStart(6, "0")}</small>
                                    </div>
                                </div>

                                <div>
                                    <Link to={`./../editar?id=${client.id}`} className="btn btn-sm btn-warning" title="Editar información del cliente">
                                        <i className="fa-regular fa-pen-square"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 bg-white">
                            <div className="row row-cols-1 row-cols-md-2 g-5">
                                <div className="col">
                                    <div>
                                        <div className="mb-2 hstack gap-2">
                                            <h3 className="mb-0 fw-semibold fs-5">Membresía</h3>
                                            <div>
                                                <button className="btn btn-sm btn-light" type="button" data-bs-toggle="modal" data-bs-target="#clientMembershipModal" title="Editar membresía">
                                                    <i className="fa-regular fa-pen-square"></i>
                                                </button>

                                                <ClientMembershipModal client={client} modal={"clientMembershipModal"} onSubmit={fetchMembership}>

                                                </ClientMembershipModal>
                                            </div>
                                        </div>

                                        <div className="vstack gap-1">
                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <p className="mb-0">Estado</p>
                                                
                                                <span className={`px-3 py-2 text-bg-${color} rounded-4 small fw-semibold lh-1`}>
                                                    {client.subscriptionStatus.name}
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <p className="mb-0">Fecha de vencimiento</p>
                                                <p className="mb-0 fw-semibold text-capitalize-first">{moment(client.expiresAt).format("dddd[,] DD MMM yyyy")}</p>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <p className="mb-0">Días</p>
                                                <p className="mb-0 fw-semibold">({Math.abs(client.daysUntilExpiration)} días)</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <hr className="my-4"/>

                                    <div>
                                        <h3 className="fw-semibold fs-5">Información</h3>

                                        <div className="vstack gap-1">
                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <p className="mb-0 text-muted">Teléfono</p>
                                                <p className="mb-0 fw-semibold">{client.phone}</p>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <p className="mb-0 text-muted">Número de documento</p>
                                                <p className="mb-0 fw-semibold">{client.documentNumber}</p>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between gap-2">
                                                <p className="mb-0 text-muted">Se unió</p>
                                                <p className="mb-0 fw-semibold">{moment(client.createdAt).format("l")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <h3 className="fw-semibold fs-5">Historial de pagos</h3>

                                    <div className="mb-3 p-3 text-bg-light border rounded shadow-sm">
                                        {(client.payments.length > 0) ?
                                            <div className="table-responsive">
                                                <table className="table table-transparent">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-muted fw-semibold">Fecha</th>
                                                            <th className="text-muted fw-semibold">Monto</th>
                                                            <th className="text-muted fw-semibold">Detalles</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {client.payments.map(payment =>
                                                        {
                                                            return (
                                                                <tr key={payment.id}>
                                                                    <td>{moment(payment.date).format("L")}</td>
                                                                    <td className="fw-semibold text-nowrap">$ {payment.amount.toLocaleString()}</td>
                                                                    <td>{payment.details}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <p className="mb-0 text-muted">Aún no hay pagos registrados</p>
                                        }
                                    </div>

                                    <div className="text-end">
                                        <button className="btn btn-primary fw-bold" type="button" data-bs-toggle="modal" data-bs-target="#clientPaymentModal">
                                            <div className="hstack gap-2">
                                                <i className="fa-solid fa-dollar"></i>
                                                <span>Registrar pago</span>
                                            </div>
                                        </button>
                                    </div>

                                    <ClientPaymentModal client={client} plans={client.plans} modal={"clientPaymentModal"} onSubmit={fetchMembership}>

                                    </ClientPaymentModal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 