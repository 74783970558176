import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";


export default function Expenses()
{
    const {managedGym} = useUser();

    const [expenses, setExpenses] = useState([]);
    const [loaded, setLoaded] = useState({});


    const fetchExpenses = useCallback(() =>
    {
        if(!managedGym) return;

        setLoaded(copy => ({...copy, list: false}));
        request("GET", `/expenses/?gymId=${managedGym.id}`).then(response => 
        {
            setExpenses(response.data);
            setLoaded(copy => ({...copy, list: true}));
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedGym]);


    useEffect(() =>
    {
        fetchExpenses();

        document.title = `Gastos - ${managedGym.name}`;
    }, [managedGym, fetchExpenses]);


    // Eliminar gasto
    const onDeleteExpense = expense =>
    {
        Swal.fire(
        {
            title: "Eliminar gasto",
            html: 
            `
                <p>Esta opción es para eliminar gastos que fueron registrados erróneamente, ¿deseas continuar?</p>
                <p class="mb-0 fw-bold">Datos del gasto</p>
                <p class="mb-0">Razón: <span class="fw-semibold">${expense.type.name}</span></p>
                <p class="mb-0">Monto: <span class="fw-semibold">$ ${expense.amount.toLocaleString()}</span></p>
                <p class="mb-0">Método de pago: <span class="fw-semibold">${expense.paymentMethod.name}</span></p>
            `,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        })
        .then(response =>
        {
            if(response.isConfirmed) // Eliminar
            {
                request("DELETE", `/expenses/delete?id=${expense.id}`).then(response =>
                {
                    Swal.fire({title: "Gasto eliminado correctamente", icon: "success", toast: true, position: "top-end", timer: 3000});
                    fetchExpenses();
                });
            }
        });
    };


    return (
        <main>
            <div className="mb-4 d-flex flex-wrap align-items-center justify-content-between gap-2">
                <h3 className="mb-0 fw-semibold">Gastos</h3>

                <Link to={"form"} type="button" className="btn btn-primary fw-semibold">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-plus"></i>
                        <span>Registrar gasto</span>
                    </div>
                </Link>
            </div>

            {/*  */}
            {(loaded.list) ?
                <Fragment>
                    {(expenses.length > 0) ?
                        <div className="table-responsive">
                            <table className="table table-sm table-transparent table-bordered table-hover caption-top align-middle">
                                <caption>Lista de gastos</caption>

                                <thead>
                                    <tr className="text-bg-primary">
                                        <th className="text-reset">Fecha</th>
                                        <th className="text-reset">Razón</th>
                                        <th className="text-reset">Monto</th>
                                        <th className="text-reset">Método de pago</th>
                                        <th className="text-reset">Notas adicionales</th>
                                        <th className="text-reset"></th>
                                    </tr>
                                </thead>
        
                                <tbody className="bg-white">
                                    {expenses.map(expense =>
                                    {
                                        return (
                                            <tr key={expense.id}>
                                                <td>
                                                    <span className="text-capitalize-first">
                                                        {(new Date().toLocaleDateString() === new Date(expense.createdAt).toLocaleDateString()) ?
                                                            (moment(expense.createdAt).calendar())
                                                            :
                                                            (moment(expense.createdAt).format("L HH:mm"))
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    <p className="mb-0 fw-semibold">{expense.type.name}</p>
                                                    <small className="text-muted">{expense.type.description}</small>
                                                </td>
                                                <td className="text-nowrap fw-bold">$ {expense.amount.toLocaleString()}</td>
                                                <td>{expense.paymentMethod.name}</td>
                                                <td>{expense.details}</td>
                                                <td>
                                                    <div className="hstack justify-content-center gap-2">
                                                        <Link to={`form?id=${expense.id}`} className="btn btn-sm btn-warning" title="Editar">
                                                            <i className="fa-regular fa-pen-square"></i>
                                                        </Link>

                                                        <button className="btn btn-sm btn-danger" type="button" onClick={() => onDeleteExpense(expense)} title="Eliminar">
                                                            <i className="fa-regular fa-trash-can"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="p-4 bg-white border rounded-3">
                            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                                <h5 className="mb-0">Aún no hay gastos registrados</h5>

                                <Link to={"form"} type="button" className="btn btn-primary">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-plus"></i>
                                        <span>Registrar gasto</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }
                </Fragment>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 