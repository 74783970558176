import React, {Fragment, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";

import {request} from "../../services/AxiosService";
import {useUser} from "../../contexts/UserContext";
import ClientCard from "../../components/panel/ClientCard";


export default function Clients()
{
    const {managedGym} = useUser();
    const navigate = useNavigate();

    const [pagination, setPagination] = useState(null);
    const [summary, setSummary] = useState({});
    const [filters, setFilters] = useState(null);
    const [loaded, setLoaded] = useState({});


    useEffect(() =>
    {
        if(!managedGym) return;

        // Establecer filtros por defecto
        setFilters({pageNumber: 0, pageSize: 48, status: "ACTIVE"});

        // Obtener resumen de clientes
        request("GET", `/clients/summary?gymId=${managedGym.id}`).then(response =>
        {
            setSummary(response.data);
            setLoaded(copy => ({...copy, summary: true}));
        });

        document.title = `Clientes - ${managedGym.name}`;
    }, [managedGym]);


    // Buscar clientes cuando cambien los filtros
    useEffect(() =>
    {
        if(!managedGym || !filters) return;

        request("GET", `/clients/?gymId=${managedGym.id}&pageNumber=${filters.pageNumber}&pageSize=${filters.pageSize}&status=${filters.status}`).then(response => 
        {
            setPagination(response.data);
            setLoaded(copy => ({...copy, list: true}));
        })
        .catch(() => {
            Swal.fire("Oops", "Ocurrió un error y no pudimos identificarlo. Intenta recargando la página.", "error");
        });
    }, [managedGym, filters]);
    

    const getPagination = (currentPage, totalPages, maxVisiblePages = 10) => 
    {
        // Determina el rango de páginas a mostrar
        const half = Math.floor(maxVisiblePages / 2);
        let start = Math.max(0, currentPage - half);
        let end = Math.min(totalPages - 1, currentPage + half);

        // Ajusta el rango si hay menos de `maxVisiblePages` al principio o al final
        if(end - start + 1 < maxVisiblePages) 
        {
            if(start === 0) end = Math.min(totalPages - 1, start + maxVisiblePages - 1);
            else if(end === totalPages - 1) start = Math.max(0, end - maxVisiblePages + 1);
        }

        // Genera el array con las páginas visibles
        const pages = [];
        for(let i = start; i <= end; i++) {
            pages.push(i);
        }
        return pages;
    }


    // Filtros
    const onChangeFilters = (filter, value) =>
    {
        setLoaded({...loaded, list: false});
        setFilters({...filters, [filter]: value});
    };


    // AsyncSelect
    let debounceTimeout = null;

    const promiseOptions = (inputValue) => 
    {
        if(inputValue.length < 3) return null;

        return new Promise((resolve) => 
        {
            if(debounceTimeout) clearTimeout(debounceTimeout); // Limpia el timeout anterior si el usuario sigue escribiendo
      
            // Establece un nuevo timeout de 500ms (o el tiempo que prefieras)
            debounceTimeout = setTimeout(() => 
            {
                // Realiza la petición después del delay
                request("GET", `/clients/search?gymId=${managedGym.id}&query=${inputValue}`).then((response) => 
                {
                    const options = response.data.map((option) => ({value: option.id, label: `${option.name} (${option.subscriptionStatus.name})`, data: option}));
                    resolve(options); // Resuelve la promesa con las opciones
                })
                .catch(() => {
                    resolve([]); // Si hay un error, devuelve un array vacío
                });
            }, 750); // 500ms de delay para el debounce
        });
    };

    const onSelectClient = response =>
    {
        const client = response.data;
        navigate(`membresia?id=${client.id}`);
    };


    return (
        <main>
            <div className="mb-4 d-flex flex-wrap align-items-center justify-content-between gap-2">
                <h3 className="mb-0 fw-semibold">Clientes</h3>

                <Link to={"añadir"} type="button" className="btn btn-primary fw-semibold">
                    <div className="hstack gap-2">
                        <i className="fa-regular fa-user-plus"></i>
                        <span>Nuevo cliente</span>
                    </div>
                </Link>
            </div>

            {/* Resumen */}
            {(loaded.summary) &&
                <div className="mb-4 row row-cols-1 row-cols-sm-2 row-cols-xl-4 g-2">
                    <div className="col">
                        <div className="p-4 bg-white rounded-3 border shadow-sm">
                            <div className="hstack gap-3">
                                <div className="d-flex align-items-center justify-content-center text-bg-primary bg-opacity-10 rounded-3" style={{minWidth: "3.5rem", minHeight: "3.5rem"}}>
                                    <i className="fa-regular fa-check fa-fw fa-lg text-primary"></i>
                                </div>

                                <div>
                                    <h3 className="mb-1 fs-6 fw-semibold text-uppercase">Activos</h3>
                                    <p className="mb-0 fs-4 fw-bold lh-1">{summary.active}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="p-4 bg-white rounded-3 border shadow-sm">
                            <div className="hstack gap-3">
                                <div className="d-flex align-items-center justify-content-center text-bg-warning bg-opacity-10 rounded-3" style={{minWidth: "3.5rem", minHeight: "3.5rem"}}>
                                    <i className="fa-regular fa-timer fa-fw fa-lg text-warning"></i>
                                </div>

                                <div>
                                    <h3 className="mb-1 fs-6 fw-semibold text-uppercase">Pendientes</h3>
                                    <p className="mb-0 fs-4 fw-bold lh-1">{summary.pending}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="p-4 bg-white rounded-3 border shadow-sm">
                            <div className="hstack gap-3">
                                <div className="d-flex align-items-center justify-content-center text-bg-dark bg-opacity-10 rounded-3" style={{minWidth: "3.5rem", minHeight: "3.5rem"}}>
                                    <i className="fa-regular fa-user-xmark fa-fw fa-lg text-dark"></i>
                                </div>

                                <div>
                                    <h3 className="mb-1 fs-6 fw-semibold text-uppercase">Expirados</h3>
                                    <p className="mb-0 fs-4 fw-bold lh-1">{summary.expired}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="p-4 bg-white rounded-3 border shadow-sm">
                            <div className="hstack gap-3">
                                <div className="d-flex align-items-center justify-content-center text-bg-success bg-opacity-10 rounded-3" style={{minWidth: "3.5rem", minHeight: "3.5rem"}}>
                                    <i className="fa-regular fa-users fa-fw fa-lg text-success"></i>
                                </div>

                                <div>
                                    <h3 className="mb-1 fs-6 fw-semibold text-uppercase">Total</h3>
                                    <p className="mb-0 fs-4 fw-bold lh-1">{summary.total}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/*  */}
            {(loaded.list) ?
                <Fragment>
                    {(pagination.content.length > 0) ?
                        <Fragment>
                            <div className="mb-3">
                                <div className="input-group flex-nowrap">
                                    <div className="input-group-text">
                                        <i className="fa-regular fa-search"></i>
                                    </div>

                                    <div className="flex-fill">
                                        <AsyncSelect 
                                            loadOptions={promiseOptions}
                                            placeholder="Buscar cliente"
                                            onChange={onSelectClient}
                                            loadingMessage={e => `${(e.inputValue.length < 3) ? ("Ingresa al menos 3 letras") : ("Buscando...")}`}
                                            noOptionsMessage={e => `${(e.inputValue.length < 3) ? ("Escribe el nombre, teléfono o número de documento del cliente") : ("Sin resultados")}`}
                                            value={null}
                                            // components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            styles={
                                            {
                                                loadingMessage: (base) => ({...base, textAlign: "left"}),
                                                noOptionsMessage: (base) => ({...base, textAlign: "left"})
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <nav aria-label="Page navigation example">
                                <ul className="pagination flex-wrap justify-content-end">
                                    <li className={`page-item ${(pagination.first) ? ("disabled") : ("")}`}>
                                        <button className="page-link" title="Primera página" onClick={() => onChangeFilters("pageNumber", 0)}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </button>
                                    </li>
                                    <li className={`page-item ${(pagination.first) ? ("disabled") : ("")}`}>
                                        <button className="page-link" title="Página anterior" onClick={() => onChangeFilters("pageNumber", filters.pageNumber - 1)}>
                                            <span aria-hidden="true">&lsaquo;</span>
                                        </button>
                                    </li>

                                    {getPagination(filters.pageNumber, pagination.totalPages, 10).map(i =>
                                    {
                                        return (
                                            <li className={`page-item ${(pagination.number === i) ? ("active") : ("")}`} key={i} style={{zIndex: "0"}}>
                                                <button className="page-link" title={"Ir a la página " + (i + 1)} onClick={() => onChangeFilters("pageNumber", i)}>
                                                    {i + 1}
                                                </button>
                                            </li>
                                        );
                                    })}

                                    <li className={`page-item ${(pagination.last) ? ("disabled") : ("")}`}>
                                        <button className="page-link" title="Página siguiente" onClick={() => onChangeFilters("pageNumber", filters.pageNumber + 1)}>
                                            <span aria-hidden="true">&rsaquo;</span>
                                        </button>
                                    </li>
                                    <li className={`page-item ${(pagination.last) ? ("disabled") : ("")}`} onClick={() => onChangeFilters("pageNumber", pagination.totalPages - 1)}>
                                        <button className="page-link" title="Última página">
                                            <span aria-hidden="true">&raquo;</span>
                                        </button>
                                    </li>
                                </ul>
                            </nav>

                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-2 row-cols-xl-3 g-2">
                                {pagination.content.map(client =>
                                {
                                    return (
                                        <div className="col" key={client.id}>
                                            <ClientCard client={client}>

                                            </ClientCard>
                                        </div>
                                    );
                                })}
                            </div>
                        </Fragment>
                        :
                        <div className="p-4 bg-white border rounded-3">
                            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                                <h5 className="mb-0">Aún no hay clientes</h5>

                                <Link to={"añadir"} type="button" className="btn btn-primary">
                                    <div className="hstack gap-2">
                                        <i className="fa-regular fa-user-plus"></i>
                                        <span>Añadir cliente</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }
                </Fragment>
                :
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
        </main>
    );
} 